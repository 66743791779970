import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import styles from './Modal.module.scss';

interface Props {
  email: string;
  initModalName: string;
  isShowModal: boolean;
  setShowModal: any;
}

const MODALS = {
  DEFAULT: 'default',
  DONE: 'done',
};
const Modal: React.FC<Props> = ({ email, isShowModal, setShowModal, initModalName }) => {
  const [modalName, setModalName] = React.useState<string>(MODALS.DEFAULT);
  const navigate = useNavigate();
  useCheckToken();

  const modalDom = () => {
    const handleClick = () => {
      const token = getToken();

      const params = {
        user: {
          email,
        },
      };
      axios
        .put('/api/v14/users/profiles', params, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setModalName(MODALS.DONE);
        })
        .catch(() => {
          navigate('/account-verify/failure');
        });
    };

    const handleClose = () => {
      setShowModal(false);
    };

    if (modalName === MODALS.DEFAULT && initModalName === MODALS.DEFAULT) {
      return (
        <div className={`${styles.modalWrap}`}>
          <div className={`${styles.container}`}>
            <p className={`${styles.leadContent}`}>メールアドレス認証をお願いします</p>
            <p className={`${styles.followContent} ${styles.marginBottomLg}`}>
              現在登録されているメールアドレスは{email}です。
              <br />
              企業からのスカウトを受け取るためにはメールアドレス認証が必要です。
            </p>
            <div className={`${styles.buttonGroup}`}>
              <button
                type="button"
                className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}
                onClick={handleClick}
              >
                認証メール再送信
                <span className={`${styles.materialIcons}`}>
                  <ArrowForwardIosRoundedIcon />
                </span>
              </button>
              <Link
                to="/mypage/edit#mail-address"
                className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}
              >
                メールアドレスを変更する
                <span className={`${styles.materialIcons}`}>
                  <ArrowForwardIosRoundedIcon />
                </span>
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={`${styles.modalWrap}`}>
        <div className={`${styles.container}`}>
          <p className={`${styles.leadContent}`}>認証メールを送信しました</p>
          <p className={`${styles.followContent} ${styles.marginBottomLg}`}>
            {email}に認証メールを送信しました。
            <br />
            メール内のリンクからメールアドレスの認証を完了してください。
          </p>
          <div className={`${styles.buttonGroup}`}>
            <button
              type="button"
              className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}
              onClick={handleClose}
            >
              閉じる
              <span className={`${styles.materialIcons}`}>
                <ArrowForwardIosRoundedIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return isShowModal ? modalDom() : <></>;
};

export default Modal;
