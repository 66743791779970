import { Breadcrumbs, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import useAuth from 'hooks/useAuth';
import useScrollTop from 'hooks/useScrollTop';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './CongratulatoryGiftComplete.module.scss';

const CongratulatoryGiftComplete: FC = () => {
  useAuth();
  useScrollTop();

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
          <MuiLink underline="hover" color="inherit" to="/" component={Link}>
            中国語・英語を活かしてグローバル転職 JaU
          </MuiLink>
          <Typography color="text.primary">お祝い金申請</Typography>
        </Breadcrumbs>
        <h1 className={`${styles.titleMain}`}>お祝い金申請</h1>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>お祝い金申請完了</h2>
          <div className={`${styles.panelDefaultContent}`}>
            <p className={styles.head}>お祝い金申請が完了しました</p>
            <div className={styles.textWrap}>
              <p>この度はご内定おめでとうございます！</p>
              <p>ご入社後、JaU運営事務局よりJaUにご登録のメールアドレスへお祝い金のご案内メールを送付いたします。</p>
              <p>お祝い金を受領されるまでJaUを退会しないようお願いします。</p>
            </div>
            <div className={`${styles.buttonGroup}`}>
              <Link to="/mypage" className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}>
                完了
              </Link>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default CongratulatoryGiftComplete;
