import { CircularProgress } from '@mui/material';
import { FC, useCallback } from 'react';
import { Control, Controller, FormState, UseFieldArrayReturn, UseFormRegister } from 'react-hook-form';
import { MypageEditFormData, Option } from 'types';
import { isDuplicated, isIn, required, validate } from 'utils/validation';
import Select from 'components/parts/Select';
import AddSkillButton from 'components/parts/AddSkillButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AsyncCreatableSelectInput from 'components/parts/AsyncCreatableSelectInput';
import getSkills from 'requests/getSkills';
import ItemName from 'components/parts/ItemName';
import { useNavigate } from 'react-router-dom';
import styles from './Skill.module.scss';

type Props = {
  skillLevels: Option[];
  skillsData: MypageEditFormData['skills'];
  control: Control<MypageEditFormData>;
  loadingInitial: boolean;
  register: UseFormRegister<MypageEditFormData>;
  fields: UseFieldArrayReturn<MypageEditFormData, 'skills', 'key'>['fields'];
  append: UseFieldArrayReturn<MypageEditFormData, 'skills', 'key'>['append'];
  remove: UseFieldArrayReturn<MypageEditFormData, 'skills', 'key'>['remove'];
  errors: FormState<MypageEditFormData>['errors'];
};

const MypageEditSkill: FC<Props> = ({
  skillLevels,
  skillsData,
  control,
  loadingInitial,
  register,
  fields,
  append,
  remove,
  errors,
}) => {
  const addSkill = useCallback(
    () =>
      append({
        skill: '',
        skillLevel: '',
      }),
    [append],
  );
  const navigate = useNavigate();
  const loadSkills = (inputValue: string) => getSkills(inputValue, navigate);
  return (
    <section className={`${styles.panelDefault}`}>
      <h2 className={`${styles.panelDefaultTitle}`}>スキル</h2>
      <div className={`${styles.panelDefaultContent}`}>
        <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
          <tbody>
            <tr>
              <th className={`${styles.skillTitleSp}`}>
                <div className={styles.tableHeader}>
                  <ItemName required>スキルと経験年数</ItemName>
                </div>
              </th>
              <td className={`${styles.skillContent}`}>
                {!loadingInitial && skillLevels.length > 0 ? (
                  fields.map((field, index) => {
                    return (
                      <div className={`${styles.skillWrapper}`} key={field.key}>
                        <div className={`${styles.skillTitlePc}`}>
                          {index === 0 && (
                            <div className={styles.tableHeader}>
                              <ItemName required>スキルと経験年数</ItemName>
                            </div>
                          )}
                        </div>
                        <div className={styles.skill}>
                          <div className={styles.skillFormsWrapper}>
                            <div
                              className={`${styles.skillForm} ${
                                errors?.skills && errors.skills[index]?.skill?.message ? styles.errorForm : ''
                              } `}
                            >
                              <Controller
                                control={control}
                                name={`skills.${index}.skill`}
                                rules={{
                                  required: required('スキル', index === 0),
                                  validate: {
                                    duplicated: (value) =>
                                      validate(
                                        value === '' ||
                                          isDuplicated(
                                            value,
                                            skillsData.map((s) => s.skill),
                                          ),
                                        'スキル',
                                        'が重複しています。',
                                      ),
                                  },
                                }}
                                render={({ field: { onChange, value } }) => {
                                  const skillValue = value
                                    ? {
                                        value,
                                        label: value,
                                      }
                                    : [];
                                  return (
                                    <AsyncCreatableSelectInput
                                      placeholder="入力/選択してください"
                                      loadOptions={loadSkills}
                                      value={skillValue}
                                      onChange={(val: any) => onChange(val?.value ?? '')}
                                    />
                                  );
                                }}
                              />
                            </div>
                            <div className={styles.skillLevelForm}>
                              <Select
                                className={
                                  errors?.skills && errors.skills[index]?.skillLevel?.message ? styles.errorForm : ''
                                }
                                placeholder="年"
                                options={skillLevels}
                                control={control}
                                useFormRegisterReturn={register(`skills.${index}.skillLevel`, {
                                  required: required('経験年数', index === 0),
                                  validate: {
                                    format: (value) =>
                                      value === '' ||
                                      validate(
                                        isIn(
                                          value,
                                          skillLevels.map((sl) => sl.id),
                                        ),
                                        '経験年数',
                                      ),
                                  },
                                })}
                              />
                            </div>
                            {index > 0 && (
                              <div className={styles.removeButton}>
                                {/* eslint-disable-next-line */}
                                <button type="button" onClick={() => remove(index)}>
                                  <RemoveCircleIcon fontSize="large" />
                                </button>
                              </div>
                            )}
                          </div>
                          <div className={styles.errorField}>
                            {/* エラーメッセージ */}
                            {errors?.skills && (
                              <>
                                <p className={styles.errorMessage}>{errors.skills[index]?.skill?.message}</p>
                                <p className={styles.errorMessage}>{errors.skills[index]?.skillLevel?.message}</p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <CircularProgress />
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className={styles.addWorkHistoryWrapper}>
          <AddSkillButton handleClick={addSkill} />
        </div>
      </div>
    </section>
  );
};

export default MypageEditSkill;
