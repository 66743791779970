import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import ItemName from 'components/parts/ItemName';
import Select from 'components/parts/Select';
import SelectInput from 'components/parts/SelectInput';
import { FC } from 'react';
import { Control, Controller, FormState, UseFormRegister } from 'react-hook-form';
import { MypageEditFormData, Option } from 'types';
import { isIn, isNotSameLanguage, pattern, required, validate } from 'utils/validation';
import styles from './Language.module.scss';

type Props = {
  languages: Option[];
  filteredAcquireLanguages: Option[];
  jlptGrades: Option[];
  hskGrades: Option[];
  chineseGrades: Option[];
  loadingInitial: boolean;
  register: UseFormRegister<MypageEditFormData>;
  control: Control<MypageEditFormData>;
  errors: FormState<MypageEditFormData>['errors'];
};

const MypageEditLanguage: FC<Props> = ({
  languages,
  filteredAcquireLanguages,
  jlptGrades,
  hskGrades,
  chineseGrades,
  loadingInitial,
  register,
  control,
  errors,
}) => {
  return (
    <section className={`${styles.panelDefault}`}>
      <h2 className={`${styles.panelDefaultTitle}`}>語学</h2>
      <div className={`${styles.panelDefaultContent}`}>
        <table className={`${styles.tableDefault}`}>
          <tbody>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>母国語</ItemName>
                </div>
              </th>
              <td>
                <Select
                  className={errors?.languageId?.message ? styles.errorForm : ''}
                  placeholder="選択してください"
                  options={languages}
                  control={control}
                  useFormRegisterReturn={register('languageId', {
                    required: required('母国語'),
                    validate: {
                      format: (value) =>
                        validate(
                          isIn(
                            value,
                            languages.map((l) => l.id),
                          ),
                          '母国語',
                        ),
                    },
                  })}
                />
                <p className={styles.errorMessage}>{errors?.languageId?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>第二言語</ItemName>
              </th>
              <td>
                {filteredAcquireLanguages.length > 0 && !loadingInitial ? (
                  <div className={styles.acquireLanguage}>
                    <Controller
                      control={control}
                      name="acquireLanguage"
                      rules={{
                        validate: {
                          format: (values) =>
                            validate(
                              !Array.isArray(values) ||
                                values.every((v) =>
                                  isIn(
                                    v,
                                    filteredAcquireLanguages.map((al) => al.id),
                                  ),
                                ),
                              '第二言語',
                            ),
                          isNotSameLanguage: (values) =>
                            validate(
                              !Array.isArray(values) || isNotSameLanguage(values),
                              '第二言語',
                              'には同じ言語は複数設定できません。',
                            ),
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <SelectInput
                            placeholder="入力/選択してください"
                            isMulti
                            options={filteredAcquireLanguages}
                            value={
                              value
                                ? filteredAcquireLanguages
                                    .filter((al) => value.includes(al.id))
                                    .map((al) => ({
                                      value: al.id,
                                      label: al.name,
                                    }))
                                : []
                            }
                            onChange={(val: any[]) => onChange(val.map((v) => v.value))}
                          />
                        );
                      }}
                    />
                  </div>
                ) : (
                  <CircularProgress />
                )}
                <p className={styles.errorMessage}>{(errors?.acquireLanguage as any)?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>JLPT 日本語能力試験</ItemName>
              </th>
              <td>
                <Select
                  className={errors?.testJlptGradeId?.message ? styles.errorForm : ''}
                  placeholder="資格なし"
                  options={jlptGrades}
                  control={control}
                  useFormRegisterReturn={register('testJlptGradeId', {
                    validate: {
                      format: (value) =>
                        validate(
                          value === '' ||
                            isIn(
                              value,
                              jlptGrades.map((jg) => jg.id),
                            ),
                          'JLPT 日本語能力試験',
                        ),
                    },
                  })}
                />
                <p className={styles.errorMessage}>{errors?.testJlptGradeId?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>HSK</ItemName>
              </th>
              <td>
                <Select
                  className={errors?.testHskGradeId?.message ? styles.errorForm : ''}
                  placeholder="資格なし"
                  options={hskGrades}
                  control={control}
                  useFormRegisterReturn={register('testHskGradeId', {
                    validate: {
                      format: (value) =>
                        validate(
                          value === '' ||
                            isIn(
                              value,
                              hskGrades.map((hg) => hg.id),
                            ),
                          'HSK',
                        ),
                    },
                  })}
                />
                <p className={styles.errorMessage}>{errors?.testHskGradeId?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>中国語検定</ItemName>
              </th>
              <td>
                <Select
                  className={errors?.testChineseGradeId?.message ? styles.errorForm : ''}
                  placeholder="資格なし"
                  options={chineseGrades}
                  control={control}
                  useFormRegisterReturn={register('testChineseGradeId', {
                    validate: {
                      format: (value) =>
                        value === '' ||
                        isIn(
                          value,
                          chineseGrades.map((cg) => cg.id),
                        ),
                    },
                  })}
                />
                <p className={styles.errorMessage}>{errors?.testChineseGradeId?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>TOEIC</ItemName>
              </th>
              <td>
                <div className={styles.scoreWrapper}>
                  <input
                    className={errors?.toeicScore?.message ? styles.errorForm : ''}
                    type="number"
                    placeholder="点数を入力してください"
                    {...register('toeicScore', {
                      pattern: pattern(/\d+$/, 'TOEIC'),
                    })}
                  />
                  <span>点</span>
                </div>
                <p className={styles.errorMessage}>{errors?.toeicScore?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>TOEFL</ItemName>
              </th>
              <td>
                <div className={styles.scoreWrapper}>
                  <input
                    className={errors?.toeflScore?.message ? styles.errorForm : ''}
                    type="number"
                    placeholder="点数を入力してください"
                    {...register('toeflScore', {
                      pattern: pattern(/\d+$/, 'TOEFL'),
                    })}
                  />
                  <span>点</span>
                </div>
                <p className={styles.errorMessage}>{errors?.toeflScore?.message}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default MypageEditLanguage;
