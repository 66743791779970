import { FC } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import styles from './AccountVerifyFailure.module.scss';

const AccountVerifyFailure: FC = () => {
  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
            <MuiLink underline="hover" color="inherit" to="/" component={Link}>
              中国語・英語を活かしてグローバル転職 JaU
            </MuiLink>
            <Typography color="text.primary">メールアドレス認証エラー</Typography>
          </Breadcrumbs>
          <h1 className={`${styles.titleMain}`}>メールアドレス認証エラー</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>メールアドレス認証エラー</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <p className={`${styles.leadContent}`}>メールアドレス認証ができませんでした</p>
              <p className={`${styles.followContent} ${styles.marginBottomLg}`}>
                メールアドレス認証URLの有効期限が切れたか、URLが正しくない可能性があります。
                <br />
                もう一度メールアドレス認証を行ってください。
              </p>
            </div>
            <div className={`${styles.buttonGroup}`}>
              <Link to="/mypage" className={`${styles.anchor}`}>
                メールアドレス認証はこちら
                <span className={`${styles.materialIcons}`}>
                  <ArrowForwardIosRoundedIcon />
                </span>
              </Link>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default AccountVerifyFailure;
