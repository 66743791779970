import { FC } from 'react';
import styles from './MessageBubble.module.scss';

type Props = {
  body: string;
  isUserSend: boolean;
};

const MessageBubble: FC<Props> = ({ body, isUserSend }) => {
  return (
    <div className={`${styles.messageBubble}  ${isUserSend && styles.user}`}>
      <p className={styles.body}>{body}</p>
    </div>
  );
};

export default MessageBubble;
