import { FC } from 'react';
import styles from './ProgressBar.module.scss';

type Props = {
  colorizedNumber: number;
};

const ProgressBar: FC<Props> = ({ colorizedNumber }) => {
  return (
    <div className={styles.bar}>
      {[1, 2, 3, 4, 5, 6, 7].map((value) => {
        return <div className={`${styles.block} ${colorizedNumber >= value ? styles.colorized : ''}`} key={value} />;
      })}
    </div>
  );
};

export default ProgressBar;
