import { FC } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import styles from './VerifyMailSendDone.module.scss';

const VerifyMailSendDone: FC = () => {
  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
            <MuiLink underline="hover" color="inherit" to="/" component={Link}>
              中国語・英語を活かしてグローバル転職 JaU
            </MuiLink>
            <Typography color="text.primary">認証メール送信</Typography>
          </Breadcrumbs>
          <h1 className={`${styles.titleMain}`}>メールアドレス認証</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>認証メール送信</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <p className={`${styles.leadContent}`}>入力されたメールアドレスに確認用のメールを送信しました</p>
              <p className={`${styles.followContent} ${styles.marginBottomLg}`}>
                届いたメールを開き、記載されたURLをクリックしてメールアドレスの認証を完了してください。
              </p>
              <p className={`${styles.notice} ${styles.marginBottomLg}`}>
                ※au、docomo、Softbank等のキャリアメールは、設定に関わらず受け取れない場合があります。
                <br />
                他のメールアドレスで再度お試しください。
              </p>
            </div>
            <div className={`${styles.buttonGroup}`}>
              <Link to="/signup" className={`${styles.anchor}`}>
                <span>メールが届いていない方はこちら</span>
                <div className={styles.icons}>
                  <ArrowForwardIosRoundedIcon />
                </div>
              </Link>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default VerifyMailSendDone;
