import { ChangeEvent, FC, FormEvent, useRef } from 'react';
import FileSelectIcon from 'images/messages/file_select_icon.svg';
import CrossIcon from 'images/messages/cross_icon.svg';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { MessageFormData } from 'types';
import { required } from 'utils/validation';
import { CircularProgress } from '@mui/material';
import styles from './MessageSend.module.scss';

type Props = {
  errors: FieldErrors<MessageFormData>;
  isSubmitting: boolean;
  register: UseFormRegister<MessageFormData>;
  watch: UseFormWatch<MessageFormData>;
  submit: (e: FormEvent) => void;
  handleChangeFiles: (e: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFile: (fileIndex: number) => void;
};

const MessageSend: FC<Props> = ({
  errors,
  isSubmitting,
  register,
  watch,
  submit,
  handleChangeFiles,
  handleDeleteFile,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.textContent = `${e.target.value}\u200B`;
    }
  };
  const { body, attachedFiles } = watch();

  return (
    <form onSubmit={submit} className={styles.messageSend}>
      <div className={styles.messageInputArea}>
        <div ref={ref} className={styles.dummyArea} />
        <textarea
          {...register('body', {
            required: attachedFiles.length === 0 && required('メッセージ'),
          })}
          placeholder="こちらで入力してください。"
          onChange={(e) => {
            register('body').onChange(e);
            handleChange(e);
          }}
        />
      </div>
      {errors?.body?.message && <p className={styles.errorMessage}>{errors?.body?.message}</p>}
      <div className={styles.fileSelectArea}>
        <div className={styles.selectedFileArea}>
          {Array.isArray(attachedFiles) &&
            attachedFiles.map((attachedFile, index) => (
              <div key={attachedFile.name} className={styles.selectedFile}>
                {attachedFile.name}
                {/* eslint-disable-next-line */}
                <button type="button" onClick={() => handleDeleteFile(index)}>
                  <img src={CrossIcon} />
                </button>
              </div>
            ))}
        </div>
        <div className={styles.messageInputBottom}>
          <label>
            <input
              type="file"
              onChange={handleChangeFiles}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                e.target.value = '';
              }}
            />
            <img src={FileSelectIcon} alt="" />
            <span>添付ファイル</span>
          </label>
          <button disabled={body === '' && attachedFiles.length === 0} type="submit" className={styles.submitButton}>
            {isSubmitting ? (
              <span className={styles.loading}>
                <CircularProgress size={10} />
              </span>
            ) : (
              <span>送る</span>
            )}
          </button>
        </div>
        {(errors?.attachedFiles as any)?.message && (
          <p className={styles.errorMessage}>{(errors?.attachedFiles as any).message}</p>
        )}
      </div>
    </form>
  );
};

export default MessageSend;
