import useSWR from 'swr';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCallback } from 'react';

/**
 * ユーザ情報
 */
const useGetUser = () => {
  const getUserName = useCallback((url: string, token: string) => {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  const token = getToken();

  const { data, error, mutate } = useSWR(token ? ['/api/v14/user', token] : null, getUserName, {
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
};

export default useGetUser;
