import { CircularProgress } from '@mui/material';
import NextButton from 'components/parts/NextButton';
import Select from 'components/parts/Select';
import useGetEducationMaster from 'hooks/Master/useGetEducationMaster';
import useAuth from 'hooks/useAuth';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { EducationFormData } from 'types';
import { useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import { isIn, required, validate } from 'utils/validation';
import ProgressBar from 'components/parts/ProgressBar';
import BackButton from 'components/parts/BackButton';
import useScrollTop from 'hooks/useScrollTop';
import AsyncCreatableSelectInput from 'components/parts/AsyncCreatableSelectInput';
import getUniversityName from 'requests/getUniversityName';
import ItemName from 'components/parts/ItemName';
import styles from './Education.module.scss';

const Education: FC = () => {
  const auth = useAuth();
  useScrollTop();
  useCheckToken();

  const navigate = useNavigate();

  const {
    watch,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<EducationFormData>({
    mode: 'onChange',
    defaultValues: {
      graduateYear: '2010',
      graduateMonth: '3',
    },
  });

  const [loadingForPrepare, setLoadingForPrepare] = useState(true);

  // 初期値セット
  useEffect(() => {
    const req = async () => {
      const token = getToken();

      const res = await axios.get('/api/v14/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const education = res.data.profiles.school;

      if (!education) {
        return;
      }

      if (education.education_id && typeof education.education_id.id === 'number' && education.education_id.id > 0) {
        setValue('educationsId', String(education.education_id.id), {
          shouldValidate: true,
        });
      }

      if (typeof education.school_name === 'string') {
        setValue('schoolName', education.school_name, {
          shouldValidate: true,
        });
      }
    };

    if (auth.id) {
      req().then(() => {
        setLoadingForPrepare(false);
      });
    }
  }, [auth.id, setValue, navigate]);

  const processing = useRef(false);
  const onSubmit = async (data: EducationFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const params: Record<string, any> = {
      profile: {
        educations_id: data.educationsId,
        school_name: data.schoolName,
      },
    };

    const token = getToken();

    const res = await axios.put('/api/v14/users/profiles', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // 処理中フラグを折る
    processing.current = false;
    if (res.status === 200 || res.status === 201) {
      // 次のページに飛ばす (就業状況)
      navigate('/work-status');
    }
  };

  const { educations } = useGetEducationMaster(navigate);

  const educationsId = watch('educationsId');

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <h1 className={`${styles.titleMain}`}>新規会員登録（無料）</h1>
        <div className={styles.progressBar}>
          <ProgressBar colorizedNumber={2} />
        </div>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>学歴</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={`${styles.panelDefaultContent}`}>
            <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
              <tbody>
                <tr>
                  <th>
                    <ItemName required>最終学歴</ItemName>
                  </th>
                  <td>
                    {educations.length > 0 && !loadingForPrepare ? (
                      <Select
                        className={errors?.educationsId?.message ? styles.errorForm : ''}
                        placeholder="選択してください"
                        options={educations}
                        control={control}
                        useFormRegisterReturn={register('educationsId', {
                          required: required('最終学歴'),
                          validate: {
                            format: (value) =>
                              validate(
                                isIn(
                                  value,
                                  educations.map((e) => e.id),
                                ),
                                '最終学歴',
                              ),
                          },
                        })}
                      />
                    ) : (
                      <CircularProgress />
                    )}
                    <p className={styles.errorMessage}>{errors?.educationsId?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>学校名</ItemName>
                  </th>
                  <td>
                    {!loadingForPrepare ? (
                      <div
                        className={`${styles.schoolName} ${
                          errors?.schoolName && errors.schoolName?.message ? styles.errorForm : ''
                        }`}
                      >
                        <Controller
                          control={control}
                          name="schoolName"
                          rules={{
                            required: required('学校名'),
                          }}
                          render={({ field: { onChange, value } }) => {
                            let schoolNameValue;
                            if (value) {
                              schoolNameValue = value
                                ? {
                                    value,
                                    label: value,
                                  }
                                : [];
                            } else {
                              schoolNameValue = '';
                            }

                            return (
                              <AsyncCreatableSelectInput
                                placeholder="学校名を入力してください"
                                // 最終学歴に専門学校/短大、大学、大学院、大学院(MBA)、大学院(博士)が選択された場合のみ大学名の選択肢を表示する
                                loadOptions={['3', '4', '5', '7', '8'].includes(educationsId) && getUniversityName}
                                value={schoolNameValue}
                                onChange={(val: any) => onChange(val?.value ?? '')}
                              />
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <CircularProgress />
                    )}
                    <p className={styles.errorMessage}>{errors?.schoolName?.message}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={`${styles.buttonGroup}`}>
              <NextButton isValid={isValid} />
              <BackButton />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default Education;
