import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { MessageExchange, MessageFormData } from 'types';
import ArrowDown from 'images/arrow_down.svg';
import { Link } from 'react-router-dom';
import IconBubble from '../MessageParts/IconBubble';
import MessageBubble from '../MessageParts/MessageBubble';
import MessageSend from '../MessageSend';
import styles from './MessageExchangeList.module.scss';
import JobOfferBubble from '../MessageParts/JobOfferBubble';

type Props = {
  messageExchanges: MessageExchange[];
  errors: FieldErrors<MessageFormData>;
  isSubmitting: boolean;
  changeSelectedId: (id: number | null) => void;
  register: UseFormRegister<MessageFormData>;
  watch: UseFormWatch<MessageFormData>;
  submit: (e: FormEvent) => void;
  handleChangeFiles: (e: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteFile: (fileIndex: number) => void;
};

const MessageExchangeList: FC<Props> = ({
  messageExchanges,
  errors,
  isSubmitting,
  changeSelectedId,
  register,
  watch,
  submit,
  handleChangeFiles,
  handleDeleteFile,
}) => {
  const [height, setHeight] = useState(0);
  const messageSendAreaRef = useRef<HTMLDivElement>();
  const setMessageSendAreaRef = useCallback((node: HTMLDivElement) => {
    messageSendAreaRef.current = node;
  }, []);

  const { body, attachedFiles, jobOffers } = watch();
  // textareaとinput fileの値が変わるごとにuseEffectを実行し、messageSendAreaの高さを取得する
  useEffect(() => {
    if (messageSendAreaRef.current) {
      setHeight(messageSendAreaRef.current.clientHeight);
    }
  }, [body, attachedFiles, jobOffers, errors?.body, errors?.attachedFiles, errors?.jobOffers]);

  const getMessageExchangeArea = (messageSendAreaHeight: number) => {
    const headerHeight = 64;
    const padding = 'calc(24px + 44px)';
    return `calc(var(--main-height) - ${headerHeight}px - ${messageSendAreaHeight}px - ${padding})`;
  };

  const displayDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()} ${date.getHours()}:${`00${date.getMinutes()}`.slice(-2)}`;
  };

  const isImage = (filename: string) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];
    const extension = filename.split('.').pop();
    if (typeof extension === 'undefined') {
      return false;
    }
    return imageExtensions.includes(extension) || imageExtensions.map((ie) => ie.toUpperCase()).includes(extension);
  };

  // 画面スクロール
  const messageExchangeAreaRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    process.nextTick(() => {
      if (messageExchangeAreaRef.current) {
        messageExchangeAreaRef.current.scrollTop = messageExchangeAreaRef.current.scrollHeight;
      }
    });
  }, [messageExchanges]);

  return (
    <>
      <div className={styles.header}>
        {/* eslint-disable-next-line */}
        <button className={styles.backButton} type="button" onClick={() => changeSelectedId(null)}>
          <img src={ArrowDown} />
        </button>
        <div className={styles.companyName}>{messageExchanges[0].companyName}</div>
        {/* お祝い金申請画面を作成したらcompany_idつきのリンクを設定する */}
        {messageExchanges[0].companyId !== 1 ? (
          <Link
            to={`/congratulatory-gift?id=${messageExchanges[0].companyId}`}
            className={styles.congratulatoryGiftLink}
          >
            お祝い金申請
          </Link>
        ) : (
          <div />
        )}
      </div>
      <div
        ref={messageExchangeAreaRef}
        style={{ height: getMessageExchangeArea(height) }}
        className={styles.messageExchangeArea}
      >
        {messageExchanges.map((me) => (
          <div key={me.id} className={`${styles.message} ${me.isUserSend && styles.user}`}>
            {me.body && (
              <div className={styles.messageBubble}>
                <MessageBubble body={me.body} isUserSend={me.isUserSend} />
              </div>
            )}
            {me.attachedFiles?.map((af) => (
              <div key={af.id} className={styles.iconBubble}>
                <IconBubble isUserSend={me.isUserSend} isImage={isImage(af.name)} filename={af.name} url={af.url} />
              </div>
            ))}
            {me.jobOffers?.map((jo) => (
              <div key={jo.id} className={styles.jobOfferBubble}>
                <JobOfferBubble occupations={jo.occupations} title={jo.title} images={jo.images} />
              </div>
            ))}
            <div className={styles.date}>{displayDate(me.createdAt)}</div>
          </div>
        ))}
      </div>
      <div ref={setMessageSendAreaRef} className={styles.messageSendArea}>
        <MessageSend
          errors={errors}
          isSubmitting={isSubmitting}
          register={register}
          watch={watch}
          submit={submit}
          handleChangeFiles={handleChangeFiles}
          handleDeleteFile={handleDeleteFile}
        />
      </div>
    </>
  );
};

export default MessageExchangeList;
