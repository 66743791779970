import { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';
import { Option } from 'types';
import axios from 'utils/axios';
import { getToken } from 'utils/token';

const useGetLanguageMaster = (
  navigate: NavigateFunction,
  setValue: UseFormSetValue<any>,
  languageId: string,
  acquireLanguage: string[],
): {
  languages: Option[];
  filteredAcquireLanguages: Option[];
  jlptGrades: Option[];
  hskGrades: Option[];
  chineseGrades: Option[];
} => {
  // 言語マスター取得
  const [languages, setLanguages] = useState<Option[]>([]);
  useEffect(() => {
    const req = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v14/master/languages', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });

      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((d) => ({
          id: String(d.id),
          name: d.name,
        }));
        setLanguages(data);
      }
    };

    req();
  }, [navigate]);

  const [acquireLanguages, setAcquireLanguages] = useState<Option[]>([]);
  const [filteredAcquireLanguages, setFilteredAcquireLanguages] = useState<Option[]>([]);
  useEffect(() => {
    const req = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v14/master/language-levels', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });

      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = languages
          .map((language) =>
            res.data.map((level: any) => ({
              id: `${language.id}-${level.id}`,
              name: `${language.name} ${level.name}`,
            })),
          )
          .flat();
        setAcquireLanguages(data);
        setFilteredAcquireLanguages(data);
      }
    };

    if (languages.length > 0) {
      req();
    }
  }, [navigate, languages]);

  useEffect(() => {
    setFilteredAcquireLanguages(
      acquireLanguages.filter((al) => {
        const acquireLanguageId = al.id.split('-')[0];
        return languageId !== acquireLanguageId;
      }),
    );

    if (acquireLanguage) {
      setValue(
        'acquireLanguage',
        acquireLanguage?.filter((al) => {
          const acquireLanguageId = al.split('-')[0];
          return languageId !== acquireLanguageId;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId, navigate]);

  // 言語資格マスター取得
  const [jlptGrades, setJlptGrades] = useState<Option[]>([]);
  const [hskGrades, setHskGrades] = useState<Option[]>([]);
  const [chineseGrades, setChineseGrades] = useState<Option[]>([]);
  useEffect(() => {
    const jlptReq = async (token: string | null) => {
      const res = await axios
        .get('/api/v14/master/test-jlpts', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });

      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((d) => ({
          id: String(d.id),
          name: d.name,
        }));

        setJlptGrades(data);
      }
    };

    const hskReq = async (token: string | null) => {
      const res = await axios
        .get('/api/v14/master/test-hsks', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });

      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((d) => ({
          id: String(d.id),
          name: d.name,
        }));

        setHskGrades(data);
      }
    };

    const chineseReq = async (token: string | null) => {
      const res = await axios
        .get('/api/v14/master/test-chineses', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });

      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((d) => ({
          id: String(d.id),
          name: d.name,
        }));

        setChineseGrades(data);
      }
    };

    const token = getToken();
    if (!token) {
      navigate('/login');
      return;
    }
    jlptReq(token);
    hskReq(token);
    chineseReq(token);
  }, [navigate]);

  return {
    languages,
    filteredAcquireLanguages,
    jlptGrades,
    hskGrades,
    chineseGrades,
  };
};

export default useGetLanguageMaster;
