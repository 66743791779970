import CircularProgress from '@mui/material/CircularProgress';
import AsyncSelectInput from 'components/parts/AsyncSelectInput';
import ItemName from 'components/parts/ItemName';
import { FC } from 'react';
import { Control, Controller, FormState } from 'react-hook-form';
import getQualifications from 'requests/getQualifications';
import { MypageEditFormData } from 'types';
import { useNavigate } from 'react-router-dom';
import styles from './Qualification.module.scss';

type Props = {
  loadingInitial: boolean;
  control: Control<MypageEditFormData>;
  errors: FormState<MypageEditFormData>['errors'];
};

const MypageEditQualification: FC<Props> = ({ loadingInitial, control, errors }) => {
  const navigate = useNavigate();
  const loadQualifications = (inputValue: string) => getQualifications(inputValue, navigate); // 3. 新しい関数
  return (
    <section className={`${styles.panelDefault}`}>
      <h2 className={`${styles.panelDefaultTitle}`}>資格</h2>
      <div className={`${styles.panelDefaultContent}`}>
        <table className={`${styles.tableDefault}`}>
          <tbody>
            <tr>
              <th>
                <ItemName>資格</ItemName>
              </th>
              <td>
                {!loadingInitial ? (
                  <div className={styles.acquireLanguage}>
                    <Controller
                      control={control}
                      name="qualificationIds"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <AsyncSelectInput
                            placeholder="入力/選択してください"
                            isMulti
                            loadOptions={loadQualifications}
                            value={
                              Array.isArray(value) && Array.isArray(value)
                                ? value.map((v) => ({
                                    value: v,
                                    label: v,
                                  }))
                                : []
                            }
                            onChange={(val: any[]) => onChange(val.map((v) => v.value))}
                          />
                        );
                      }}
                    />
                  </div>
                ) : (
                  <CircularProgress />
                )}
                <p className={styles.errorMessage}>{(errors?.qualificationIds as any)?.message}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default MypageEditQualification;
