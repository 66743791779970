import axios from 'utils/axios';
import { JobSummary } from 'types';

const getJobs = async (
  accountType?: number[],
  regionId?: string,
  freeword?: string,
  period?: string,
  salary?: string,
  page?: number,
): Promise<JobSummary[]> => {
  const params = new URLSearchParams({
    accountType: typeof accountType !== 'undefined' ? accountType.join(',') : '',
    regionId: typeof regionId !== 'undefined' ? regionId : '',
    freeword: typeof freeword !== 'undefined' ? freeword : '',
    period: typeof period !== 'undefined' ? period : '',
    salary: typeof salary !== 'undefined' ? salary : '',
    page: typeof page !== 'undefined' ? String(page) : '1',
  });

  const res = await axios
    .get('/api/company/search/jobs', {
      params,
    })
    .catch(() => {
      // noop
    });

  if (res && res.status === 200 && Array.isArray(res.data.job)) {
    const data = res.data.job.map((job: any) => {
      return {
        isAds: job.url !== '',
        id: job.id,
        companyName: job.company_name,
        title: job.title,
        salary: job.salary,
        closeDate: job.close_date,
        url: job.url === '' ? `/job/${job.id}` : job.url,
      };
    });

    return data;
  }

  return [];
};

export default getJobs;
