import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Option } from 'types';
import axios from 'utils/axios';
import { getToken } from 'utils/token';

const useGetEducationMaster = (
  navigate: NavigateFunction,
): {
  educations: Option[];
} => {
  const [educations, setEducations] = useState<Option[]>([]);
  useEffect(() => {
    /** 最終学歴、学校名マスター取得 */
    const req = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v14/master/educations', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200) {
        const resEducations = res.data.map((education: any) => {
          return {
            id: String(education.id),
            name: education.name,
          };
        });
        setEducations(resEducations);
      }
    };

    req();
  }, [navigate]);

  return {
    educations,
  };
};

export default useGetEducationMaster;
