/**
 * 特定の値が配列に含まれているか
 * @param needle 調べたい値
 * @param haystack 対象となる配列
 */
export const isIn = (needle: string, haystack: string[]) => {
  return haystack.includes(needle);
};

/**
 * 語学 第二言語で同じ言語で異なるレベルが選択されていないかどうか
 */
export const isNotSameLanguage = (selectedLanuageLevels: string[]) => {
  const selectedLanguages = selectedLanuageLevels.map((sll) => sll.split('-')[0]);
  const noDuplicatedLanguages = [...Array.from(new Set(selectedLanguages))];

  return selectedLanguages.length === noDuplicatedLanguages.length;
};

export const isDuplicated = (value: string, values: string[]) => {
  return values.filter((v) => v === value).length <= 1;
};

export const required = (name: string, condition?: boolean, message?: string) => {
  let customMessage = message;
  if (typeof customMessage === 'undefined') {
    customMessage = `を入力してください。`;
  }

  return {
    value: condition ?? true,
    message: `${name}${customMessage}`,
  };
};

export const pattern = (regex: RegExp, name: string, message?: string) => {
  let customMessage = message;
  if (typeof customMessage === 'undefined') {
    customMessage = `は正しい形式で入力してください。`;
  }

  return {
    value: regex,
    message: `${name}${customMessage}`,
  };
};

export const minLength = (value: number, name: string, message?: string) => {
  let customMessage = message;
  if (typeof customMessage === 'undefined') {
    customMessage = `は${value}文字以上で入力してください。`;
  }

  return {
    value,
    message: `${name}${customMessage}`,
  };
};

export const validate = (result: boolean, name: string, message?: string) => {
  let customMessage = message;
  if (typeof customMessage === 'undefined') {
    customMessage = 'は正しい形式で入力してください。';
  }

  return result || `${name}${customMessage}`;
};
