import { FC } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import useCheckNotLogin from 'hooks/useCheckNotLogin';
import useScrollTop from 'hooks/useScrollTop';
import styles from './ResetPasswordComplete.module.scss';

const ResetPasswordComplete: FC = () => {
  // ログイン中であればマイページ登録情報に飛ばす
  useCheckNotLogin();
  useScrollTop();

  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
            <MuiLink underline="hover" color="inherit" to="/" component={Link}>
              中国語・英語を活かしてグローバル転職 JaU
            </MuiLink>
            <MuiLink underline="hover" color="inherit" to="/login" component={Link}>
              ログイン
            </MuiLink>
            <Typography color="text.primary">パスワード再設定</Typography>
          </Breadcrumbs>
          <h1 className={`${styles.titleMain}`}>パスワード再設定</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>パスワードリセット完了</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <p className={`${styles.marginBottomMd}`}>パスワードリセットが完了しました。</p>
              <Link to="/login" className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}>
                ログイン
                <span className={`${styles.materialIcons}`}>
                  <ArrowForwardIosRoundedIcon />
                </span>
              </Link>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default ResetPasswordComplete;
