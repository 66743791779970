import { CircularProgress } from '@mui/material';
import ItemName from 'components/parts/ItemName';
import { FC } from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { MypageEditFormData, Option } from 'types';
import { isIn, required, validate } from 'utils/validation';
import styles from './WorkStatus.module.scss';

type Props = {
  workStatuses: Option[];
  loadingInitial: boolean;
  errors: FormState<MypageEditFormData>['errors'];
  register: UseFormRegister<MypageEditFormData>;
};

const MypageEditWorkStatus: FC<Props> = ({ workStatuses, loadingInitial, errors, register }) => {
  return (
    <section className={`${styles.panelDefault}`}>
      <h2 className={`${styles.panelDefaultTitle}`}>就業状況</h2>
      <div className={`${styles.panelDefaultContent}`}>
        <table className={`${styles.tableDefault}`}>
          <tbody>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>就業状況</ItemName>
                </div>
              </th>
              <td>
                {!loadingInitial && workStatuses ? (
                  workStatuses.map((workStatus) => {
                    return (
                      <span className={`${styles.radioButton} ${styles.marginRight8}`} key={workStatus.name}>
                        <input
                          id={`workStatus_${workStatus.id}`}
                          className={`${styles.radioButtonInput}`}
                          type="radio"
                          value={workStatus.id}
                          {...register('workStatusId', {
                            required: required('就業状況'),
                            validate: {
                              format: (value) =>
                                validate(
                                  isIn(
                                    value,
                                    workStatuses.map((ws) => ws.id),
                                  ),
                                  '就業状況',
                                ),
                            },
                          })}
                        />
                        <label className={`${styles.radioButtonLabel}`} htmlFor={`workStatus_${workStatus.id}`}>
                          {workStatus.name}
                        </label>
                      </span>
                    );
                  })
                ) : (
                  <CircularProgress />
                )}
                <p className={styles.errorMessage}>{errors?.workStatusId?.message}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default MypageEditWorkStatus;
