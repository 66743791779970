import NextButton from 'components/parts/NextButton';
import useGetWorkStatusMaster from 'hooks/Master/useGetWorkStatusMaster';
import useAuth from 'hooks/useAuth';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { WorkStatusFormData } from 'types';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import { isIn, required, validate } from 'utils/validation';
import ProgressBar from 'components/parts/ProgressBar';
import BackButton from 'components/parts/BackButton';
import useScrollTop from 'hooks/useScrollTop';
import ItemName from 'components/parts/ItemName';
import styles from './WorkStatus.module.scss';

const WorkStatus: FC = () => {
  const auth = useAuth();
  useScrollTop();
  useCheckToken();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<WorkStatusFormData>({
    mode: 'onChange',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const req = async () => {
      const token = getToken();

      const res = await axios.get('/api/v14/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const profile = res.data.profiles;

      if (
        profile.work_status &&
        profile.work_status.work_status_id &&
        typeof profile.work_status.work_status_id.id === 'number'
      ) {
        setValue('workStatusId', String(profile.work_status.work_status_id.id));
      }

      setLoading(false);
    };

    if (auth.id) {
      req();
    }
  }, [auth.id, navigate, setValue]);

  const processing = useRef(false);
  const onSubmit = async (data: WorkStatusFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const token = getToken();

    const params = {
      work_status: {
        work_status_id: data.workStatusId,
      },
    };
    const res = await axios.put('/api/v14/users/profiles', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200 || res.status === 201) {
      // 就業状況で学校に在籍中が選択された場合職務経歴画面をスキップし、語学画面に遷移させる
      const path = data.workStatusId === '3' ? '/language' : '/work-history';
      navigate(path);
    }
  };

  const { workStatuses } = useGetWorkStatusMaster(navigate);

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <h1 className={`${styles.titleMain}`}>新規会員登録（無料）</h1>
        <div className={styles.progressBar}>
          <ProgressBar colorizedNumber={3} />
        </div>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>就業状況</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={`${styles.panelDefaultContent}`}>
            <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
              <tbody>
                <tr>
                  <th>
                    <ItemName required>就業状況</ItemName>
                  </th>
                  <td>
                    {!loading &&
                      workStatuses &&
                      workStatuses.map((workStatus) => {
                        return (
                          <span className={`${styles.radioButton} ${styles.marginRight8}`} key={workStatus.name}>
                            <input
                              id={`workStatus_${workStatus.id}`}
                              className={`${styles.radioButtonInput}`}
                              type="radio"
                              value={workStatus.id}
                              {...register('workStatusId', {
                                required: required('就業状況'),
                                validate: {
                                  format: (value) =>
                                    validate(
                                      isIn(
                                        value,
                                        workStatuses.map((ws) => ws.id),
                                      ),
                                      '就業状況',
                                    ),
                                },
                              })}
                            />
                            <label className={`${styles.radioButtonLabel}`} htmlFor={`workStatus_${workStatus.id}`}>
                              {workStatus.name}
                            </label>
                          </span>
                        );
                      })}
                    <p className={styles.errorMessage}>{errors?.workStatusId?.message}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={`${styles.buttonGroup}`}>
              <NextButton isValid={isValid} />
              <BackButton />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default WorkStatus;
