import { UseFormSetError } from 'react-hook-form';
import { MypageEditFormData } from 'types';
import axios from 'utils/axios';

/**
 * プロフィール(電話番号、メールアドレスを含む)、学歴を更新する
 */
const updateProfile = async (
  setError: UseFormSetError<MypageEditFormData>,
  userId: string,
  token: string | null,
  data: MypageEditFormData,
  email: string,
) => {
  let result = true;
  let isEmailUpdated = false;

  let params: Record<string, any> = {};
  if (data.email === email) {
    // DBと同値
    if (data.emailConfirmation !== '' && data.email !== data.emailConfirmation) {
      setError('emailConfirmation', {
        type: 'inconsistent',
        message: 'メールアドレス（確認）が一致しません。',
      });
      return { result: false, isEmailUpdated };
    }
    params = {
      ...params,
      user: {
        ...params.user,
        device_type: '3',
      },
    };
  } else {
    // DBと相違値
    // eslint-disable-next-line no-lonely-if
    if (data.email === data.emailConfirmation) {
      // メールアドレス（確認）と一致
      const paramsCheckEmail = new URLSearchParams({
        email: data.email,
      });
      const resCheckEmail = await axios.post('/api/v10/user/check-email', paramsCheckEmail);
      if (resCheckEmail.data !== 1) {
        // メールアドレスが使用できないエラー
        setError('email', {
          type: 'unavailable',
          message: '登録に失敗しました。',
        });
        return { result: false, isEmailUpdated };
      }
      isEmailUpdated = true;
      params = {
        ...params,
        user: {
          ...params.user,
          email: data.email,
          device_type: '3',
        },
      };
    } else {
      // メールアドレス（確認）と不一致
      setError('emailConfirmation', {
        type: 'inconsistent',
        message: 'メールアドレス（確認）が一致しません。',
      });
      return { result: false, isEmailUpdated };
    }
  }

  if (data.phone) {
    if (!data.countryNum) {
      setError('countryNum', {
        type: 'required',
        message: '国/地域を入力してください。',
      });
      result = false;
    }
  }
  if (data.countryNum) {
    if (!data.phone) {
      setError('phone', {
        type: 'required',
        message: '電話番号を入力してください。',
      });
      result = false;
    }
  }

  params = {
    ...params,
    user: {
      ...params.user,
      phone: data.phone,
      country_num: data.countryNum,
    },
  };

  const birthday = new Date(`${data.birthyear}/${data.birthmonth}/${data.birthday}`);
  if (
    !(
      birthday.getFullYear() === Number(data.birthyear) &&
      birthday.getMonth() + 1 === Number(data.birthmonth) &&
      birthday.getDate() === Number(data.birthday)
    )
  ) {
    // 生年月日形式エラー
    setError('birthyear', {
      type: 'format',
      message: '生年月日は正しい形式で入力してください。',
    });
    setError('birthmonth', {
      type: 'format',
      message: '生年月日は正しい形式で入力してください。',
    });
    setError('birthday', {
      type: 'format',
      message: '生年月日は正しい形式で入力してください。',
    });
    result = false;
  }

  if (!result) {
    return { result, isEmailUpdated };
  }

  params = {
    ...params,
    profile: {
      last_name: data.lastName,
      first_name: data.firstName,
      last_kana: data.lastKana,
      first_kana: data.firstKana,
      country_id: data.countryId,
      region_id: data.regionId,
      sex: data.sex,
      educations_id: data.educationsId,
      school_name: data.schoolName,
      department: data.department,
    },
    work_status: {
      work_status_id: data.workStatusId,
    },
  };

  if (birthday > new Date()) {
    // 生年月日形式エラー
    setError('birthyear', {
      type: 'future',
      message: '生年月日に未来の日付が入力されています。',
    });
    setError('birthmonth', {
      type: 'future',
      message: '生年月日に未来の日付が入力されています。',
    });
    setError('birthday', {
      type: 'future',
      message: '生年月日に未来の日付が入力されています。',
    });
    return { result, isEmailUpdated };
  }

  params = {
    ...params,
    profile: {
      ...params.profile,
      birthday: `${data.birthyear}-${data.birthmonth}-${data.birthday}`,
    },
  };

  if (data.graduateYear && data.graduateMonth) {
    const graduateDate = new Date(`${data.graduateYear}/${data.graduateMonth}/1`);
    if (
      !(
        graduateDate.getFullYear() === Number(data.graduateYear) &&
        graduateDate.getMonth() + 1 === Number(data.graduateMonth)
      )
    ) {
      // 在籍期間形式エラー
      setError('graduateYear', {
        type: 'format',
        message: '卒業（予定）年月は正しい形式で入力してください。',
      });
      setError('graduateMonth', {
        type: 'format',
        message: '卒業（予定）年月は正しい形式で入力してください。',
      });
      return { result: false, isEmailUpdated };
    }

    params = {
      ...params,
      profile: {
        ...params.profile,
        graduate_year: data.graduateYear,
        graduate_month: data.graduateMonth,
      },
    };
  } else {
    params = {
      ...params,
      profile: {
        ...params.profile,
        graduate_year: null,
        graduate_month: null,
      },
    };
  }

  await axios
    .put('/api/v14/users/profiles', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => {
      isEmailUpdated = data.email !== email;
    });

  return { result, isEmailUpdated };
};

export default updateProfile;
