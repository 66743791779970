import { RefObject, useCallback, useEffect } from 'react';

const useClickOutside = (ref: RefObject<HTMLElement>, handler: any) => {
  const handleClick = useCallback(
    (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        handler({ event: e, message: 'clicked outside' });
      }
    },
    [handler, ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};

export default useClickOutside;
