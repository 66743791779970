import { FC, useRef } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link, useNavigate } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Typography from '@mui/material/Typography';
import Textarea from 'components/parts/Textarea';
import useAuth from 'hooks/useAuth';
import useScrollTop from 'hooks/useScrollTop';
import { WithdrawalFormData } from 'types';
import { useForm } from 'react-hook-form';
import axios from 'utils/axios';
import { getToken, removeToken } from 'utils/token';
import styles from './Withdrawal.module.scss';

const Withdrawal: FC = () => {
  useAuth();
  useScrollTop();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<WithdrawalFormData>({
    mode: 'onChange',
  });

  const processing = useRef(false);
  const navigate = useNavigate();
  const onSubmit = async (data: WithdrawalFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const token = getToken();
    const res = await axios.post('/api/v14/user/withdrawal', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res && res.status >= 200) {
      removeToken();
      navigate('/');
    }

    // 処理中フラグを折る
    processing.current = false;
  };

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
          <MuiLink underline="hover" color="inherit" to="/" component={Link}>
            中国語・英語を活かしてグローバル転職 JaU
          </MuiLink>
          <Typography color="text.primary">退会</Typography>
        </Breadcrumbs>
        <h1 className={`${styles.titleMain}`}>退会</h1>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>退会に関して</h2>
          <div className={`${styles.panelDefaultContent}`}>
            <p>退会手続きが完了されますと以下の内容が施行されます。</p>
            <p>・ご登録情報（プロフィール・職歴・スキル等）の一式が削除され、復元は不可能となります。</p>
            <p>・運営からお客様へのお知らせが届かなくなります。</p>
            <p>・企業とのやり取りやスカウト情報等はメッセージを含め全て削除され、アプリ内での効果は無効となります。</p>
            <br />
            <p>
              JaUでは、ユーザーの個人名・メールアドレス・電話番号等はユーザー望んで開示しない限り、採用企業側には公開されません。また、すぐに転職のご予定がなくても、職歴やスキルの情報を保持しアップデートしていくことで、よい企業への転職機会を獲得することができます。退会せずに情報を残しておくことをお勧めいたします。
            </p>
            <br />
            <p>それでも退会をご希望の方は退会理由をご記入いただいた上で、退会ボタンを押してください。</p>
            <br />
            <p>
              ※退会手続きは場合によって数日かかる場合がございます。その間は運営からのお知らせがご登録メールへ配信されますのでご了承くださいませ。
            </p>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <Textarea
                control={control}
                useFormRegisterReturn={register('reason')}
                placeholder={`退会理由をご記入ください。
例)
内定をもらいました。
企業からのスカウトが少ない。`}
              />
              {errors?.reason?.message && <p className={styles.errorMessage}>{errors.reason.message}</p>}
              <button
                type="submit"
                className={`${styles.buttonRound} ${isValid ? styles.buttonDanger : styles.buttonDanger} ${
                  styles.buttonMd
                } `}
                disabled={!isValid}
              >
                退会
                <span className={`${styles.materialIcons}`}>
                  <ArrowForwardIosRoundedIcon />
                </span>
              </button>
            </form>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Withdrawal;
