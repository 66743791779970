import { ReactElement, createElement } from 'react';

export const nl2br = (text: string): (string | ReactElement)[] => {
  const newlineRegex = /(\r\n|\r|\n)/g;

  let key = 0;
  return text.split(newlineRegex).map((line) => {
    key += 1;
    if (line.match(newlineRegex)) {
      return createElement('br', { key: `br-${key}` });
    }

    return line;
  });
};
