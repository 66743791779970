import { FC } from 'react';
import styles from './ItemName.module.scss';

type Props = {
  required?: boolean;
};

const ItemName: FC<Props> = ({ children, required }) => {
  return (
    <>
      <span className={styles.itemName}>{children}</span>
      {required && <span className={styles.required}>*</span>}
    </>
  );
};

ItemName.defaultProps = {
  required: false,
};

export default ItemName;
