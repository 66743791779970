import { FC } from 'react';
import { Link } from 'react-router-dom';
import QrWebregComp from 'images/qr_webreg_comp.png';
import AppStore from 'images/app_store.png';
import GooglePlayBadge from 'images/google_play_badge.png';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ShowingApp from 'images/showing_app.png';
import ShowingAppSp from 'images/showing_app_sp.png';
import useScrollTop from 'hooks/useScrollTop';
import styles from './RegistrationComplete.module.scss';

const RegistrationComplete: FC = () => {
  useScrollTop();

  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          <h1 className={`${styles.titleMain}`}>新規会員登録（無料）</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>登録完了</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <div className={`${styles.registrationCompleteContent}`}>
                <p>JaUの会員登録が完了しました。</p>
              </div>
            </div>
          </section>
          <div className={styles.nextMark}>Next</div>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>JaUアプリをインストール</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <div className={`${styles.installAppContent}`}>
                <p className={styles.head}>JaUアプリをインストールしましょう。</p>
                <div className={styles.textWrap}>
                  <p>
                    JaUアプリをインストールするとヘッドハンターや企業の採用担当者からスカウトを受け取り、メッセージのやりとりができるようになります。
                  </p>
                </div>
                <div className={styles.installAppDescription}>
                  <div className={styles.showingApp}>
                    <img className={styles.pc} src={ShowingApp} alt="" />
                    <img className={styles.sp} src={ShowingAppSp} alt="" />
                  </div>
                  <div className={styles.installAppArea}>
                    <p>JaUアプリダウンロードは、こちらから▼</p>
                    <div className={styles.imageField}>
                      <div className={styles.qrImage}>
                        <img src={QrWebregComp} alt="" />
                      </div>
                      <div className={styles.badges}>
                        <div className={styles.appStore}>
                          <Link
                            className={`${styles.appStoreImage}`}
                            to="https://jauapp.page.link/uimw"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={AppStore} alt="" />
                          </Link>
                        </div>
                        <div className={styles.googlePlay}>
                          <Link
                            className={styles.googleStoreImage}
                            to="https://jauapp.page.link/8u31"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={GooglePlayBadge} alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.buttonGroup}`}>
                <Link to="/mypage" className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}>
                  マイページへ
                  <span className={`${styles.materialIcons}`}>
                    <ArrowForwardIosRoundedIcon />
                  </span>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default RegistrationComplete;
