import { FC, useEffect, useState } from 'react';
import { Control, useController, UseFormRegisterReturn } from 'react-hook-form';
import styles from './Textarea.module.scss';

type Props = {
  placeholder?: string;
  useFormRegisterReturn?: UseFormRegisterReturn;
  control?: Control<any>;
};

const Textarea: FC<Props> = ({ placeholder, useFormRegisterReturn, control }) => {
  const {
    field: { value, onChange },
  } = useController({
    name: useFormRegisterReturn?.name as string,
    control,
  });

  const [isDefault, setIsDefault] = useState<boolean>(typeof value === 'undefined' || value === '');
  useEffect(() => {
    setIsDefault(typeof value === 'undefined' || value === '');
  }, [value]);

  return (
    <div className={styles.textareaDiv}>
      <textarea {...useFormRegisterReturn} onChange={onChange} />
      {isDefault && <div className={styles.placeholder}>{placeholder}</div>}
    </div>
  );
};

Textarea.defaultProps = {
  useFormRegisterReturn: undefined,
  placeholder: undefined,
  control: undefined,
};

export default Textarea;
