import { UseFormSetError } from 'react-hook-form';
import { MypageEditFormData } from 'types';
import axios from 'utils/axios';

/**
 * 希望条件を更新する
 */
const updateRequest = async (
  setError: UseFormSetError<MypageEditFormData>,
  userId: string,
  token: string | null,
  data: MypageEditFormData,
) => {
  let params: Record<string, any> = {
    industries: [],
    occupations: [],
  };

  if (data.salaryId) {
    params = {
      ...params,
      salary: {
        request_salary_id: data.salaryId,
      },
    };
  } else {
    params = {
      ...params,
      salary: {
        request_salary_id: null,
      },
    };
  }

  if (data.industryIds?.length > 0) {
    params = {
      ...params,
      industries: data.industryIds,
    };
  }

  if (data.occupationIds?.length > 0) {
    params = {
      ...params,
      occupations: data.occupationIds,
    };
  }

  if (data.locationIds?.length > 0) {
    params = {
      ...params,
      locations: data.locationIds,
    };
  }

  if (data.other) {
    params = {
      ...params,
      other: {
        content: data.other,
      },
    };
  } else {
    params = {
      ...params,
      other: {
        content: '',
      },
    };
  }

  await axios.put('/api/v14/users/requests', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return true;
};

export default updateRequest;
