import { FC } from 'react';
import { Control, FormState, UseFormClearErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { MypageEditFormData, Option, Region } from 'types';
import { isIn, pattern, required, validate } from 'utils/validation';
import Select from 'components/parts/Select';
import { birthYearOptions, monthOptions, dayOptions, countryNumberOptions } from 'utils/options';
import ItemName from 'components/parts/ItemName';
import styles from './Profile.module.scss';

type Props = {
  countries: Option[];
  regions: Region[];
  loadingInitial: boolean;
  countryId: string;
  control: Control<MypageEditFormData>;
  register: UseFormRegister<MypageEditFormData>;
  setValue: UseFormSetValue<MypageEditFormData>;
  clearErrors: UseFormClearErrors<MypageEditFormData>;
  errors: FormState<MypageEditFormData>['errors'];
};

const MypageEditProfile: FC<Props> = ({
  countries,
  regions,
  loadingInitial,
  countryId,
  control,
  register,
  setValue,
  clearErrors,
  errors,
}) => {
  return (
    <section className={`${styles.panelDefault}`}>
      <h2 className={`${styles.panelDefaultTitle}`}>基本情報</h2>
      <div className={`${styles.panelDefaultContent}`}>
        <table className={`${styles.tableDefault}`}>
          <tbody>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>氏名</ItemName>
                </div>
              </th>
              <td>
                <div className={`${styles.formGroup}`}>
                  <div className={`${styles.formGroupLeft}`}>
                    <span className={`${styles.marginRight8}`}>姓&ensp;</span>
                    <input
                      className={errors?.lastName?.message ? styles.errorForm : ''}
                      type="text"
                      placeholder="姓を入力してください"
                      {...register('lastName', {
                        required: required('姓'),
                      })}
                    />
                  </div>
                  <div className={`${styles.formGroupRight}`}>
                    <span className={`${styles.marginRight8}`}>名&ensp;</span>
                    <input
                      className={errors?.firstName?.message ? styles.errorForm : ''}
                      type="text"
                      placeholder="名を入力してください"
                      {...register('firstName', {
                        required: required('名'),
                      })}
                    />
                  </div>
                </div>
                <p className={styles.errorMessage}>{errors?.lastName?.message}</p>
                <p className={styles.errorMessage}>{errors?.firstName?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>フリガナ</ItemName>
                </div>
              </th>
              <td>
                <div className={`${styles.formGroup}`}>
                  <div className={`${styles.formGroupLeft}`}>
                    <span className={`${styles.marginRight8} ${styles.whiteSpaceNowrap}`}>セイ</span>
                    <input
                      className={errors?.lastKana?.message ? styles.errorForm : ''}
                      type="text"
                      placeholder="カタカナで入力してください"
                      {...register('lastKana', {
                        required: required('セイ', true, 'をカタカナで入力してください。'),
                        pattern: pattern(/^[ァ-ヶー]+$/, 'セイ', 'をカタカナで入力してください。'),
                      })}
                    />
                  </div>
                  <div className={`${styles.formGroupRight}`}>
                    <span className={`${styles.marginRight8} ${styles.whiteSpaceNowrap}`}>メイ</span>
                    <input
                      className={errors?.firstKana?.message ? styles.errorForm : ''}
                      type="text"
                      placeholder="カタカナで入力してください"
                      {...register('firstKana', {
                        required: required('メイ', true, 'をカタカナで入力してください。'),
                        pattern: pattern(/^[ァ-ヶー]+$/, 'メイ', 'をカタカナで入力してください。'),
                      })}
                    />
                  </div>
                </div>
                <p className={styles.errorMessage}>{errors?.lastKana?.message}</p>
                <p className={styles.errorMessage}>{errors?.firstKana?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>性別</ItemName>
                </div>
              </th>
              <td>
                <span className={`${styles.radioButton} ${styles.marginRight8}`}>
                  <input
                    id="sex_1"
                    className={`${styles.radioButtonInput}`}
                    type="radio"
                    value="1"
                    {...register('sex', {
                      required: required('性別'),
                      validate: {
                        format: (value) => validate(isIn(value, ['1', '2', '3']), '性別'),
                      },
                    })}
                  />
                  <label className={`${styles.radioButtonLabel}`} htmlFor="sex_1">
                    男性
                  </label>
                </span>
                <span className={`${styles.radioButton} ${styles.marginRight8}`}>
                  <input
                    id="sex_2"
                    className={`${styles.radioButtonInput}`}
                    type="radio"
                    value="2"
                    {...register('sex', {
                      required: required('性別'),
                      validate: {
                        format: (value) => validate(isIn(value, ['1', '2', '3']), '性別'),
                      },
                    })}
                  />
                  <label className={`${styles.radioButtonLabel}`} htmlFor="sex_2">
                    女性
                  </label>
                </span>
                <span className={`${styles.radioButton}`}>
                  <input
                    id="sex_3"
                    className={`${styles.radioButtonInput}`}
                    type="radio"
                    value="3"
                    {...register('sex', {
                      required: required('性別'),
                      validate: {
                        format: (value) => validate(isIn(value, ['1', '2', '3']), '性別'),
                      },
                    })}
                  />
                  <label className={`${styles.radioButtonLabel}`} htmlFor="sex_3">
                    その他
                  </label>
                </span>
                <p className={styles.errorMessage}>{errors?.sex?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>生年月日</ItemName>
                </div>
              </th>
              <td>
                <div className={`${styles.birth} ${styles.formGroup}`}>
                  <div className={`${styles.formGroupLeft}`}>
                    <Select
                      className={errors?.birthyear?.message ? styles.errorForm : ''}
                      options={birthYearOptions()}
                      control={control}
                      useFormRegisterReturn={register('birthyear', {
                        required: required('生年月日'),
                      })}
                      placeholder="年"
                    />
                    <span className={`${styles.marginLeft8}`}>年</span>
                  </div>
                  <div className={`${styles.formGroupCenter}`}>
                    <Select
                      className={errors?.birthmonth?.message ? styles.errorForm : ''}
                      options={monthOptions}
                      control={control}
                      useFormRegisterReturn={register('birthmonth', {
                        required: required('生年月日'),
                      })}
                      placeholder="月"
                    />
                    <span className={`${styles.marginLeft8}`}>月</span>
                  </div>
                  <div className={`${styles.formGroupRight}`}>
                    <Select
                      className={errors?.birthday?.message ? styles.errorForm : ''}
                      options={dayOptions}
                      control={control}
                      useFormRegisterReturn={register('birthday', {
                        required: required('生年月日'),
                      })}
                      placeholder="日"
                    />
                    <span className={`${styles.marginLeft8}`}>日</span>
                  </div>
                </div>
                <p className={styles.errorMessage}>
                  {errors?.birthyear?.message || errors?.birthmonth?.message || errors?.birthday?.message}
                </p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>電話番号</ItemName>
              </th>
              <td>
                <div className={styles.phoneWrap}>
                  <div className={styles.countryNumber}>
                    <Select
                      className={errors?.countryNum?.message ? styles.errorForm : ''}
                      options={countryNumberOptions}
                      placeholder="国/地域"
                      control={control}
                      useFormRegisterReturn={register('countryNum', {
                        validate: {
                          format: (value) =>
                            validate(
                              value === '' ||
                                isIn(
                                  value,
                                  countryNumberOptions.map((cn) => cn.id),
                                ),
                              '国/地域',
                            ),
                        },
                      })}
                    />
                  </div>
                  <div className={styles.phone}>
                    <input
                      className={errors?.phone?.message ? styles.errorForm : ''}
                      type="tel"
                      {...register('phone', {
                        pattern: pattern(/\d{10,}$/g, '電話番号'),
                      })}
                      placeholder="電話番号を入力してください"
                    />
                  </div>
                  <span className={`${styles.marginLeft8}`}>※ 例：12345678910（半角ハイフンなし）</span>
                </div>
                <p className={styles.errorMessage}>{errors?.countryNum?.message}</p>
                <p className={styles.errorMessage}>{errors?.phone?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>居住国</ItemName>
                </div>
              </th>
              <td>
                {countries.map((country, index) => {
                  return (
                    <span
                      key={country.id}
                      className={`${styles.radioButton} ${countries.length - 1 !== index ? styles.marginRight8 : ''}`}
                    >
                      <input
                        id={`country_${country.id}`}
                        className={`${styles.radioButtonInput}`}
                        type="radio"
                        value={country.id}
                        {...register('countryId', {
                          required: true,
                          validate: {
                            format: (value) =>
                              validate(
                                isIn(
                                  value,
                                  countries.map((c) => c.id),
                                ),
                                'お住まいの国',
                              ),
                          },
                          onChange: () => {
                            // お住まいのエリア初期化
                            setValue('regionId', '');
                            clearErrors('regionId');
                          },
                        })}
                      />
                      <label className={styles.radioButtonLabel} htmlFor={`country_${country.id}`}>
                        {country.name}
                      </label>
                    </span>
                  );
                })}
                <p className={styles.errorMessage}>{errors?.countryId?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>居住地域</ItemName>
                </div>
              </th>
              <td>
                <div className={`${styles.widthPc400}`}>
                  {!loadingInitial && countryId && (countryId === '99' || regions.length > 0) && (
                    <Select
                      className={errors?.regionId?.message ? styles.errorForm : ''}
                      options={regions.filter((r) => r.countryId === countryId)}
                      placeholder="選択してください"
                      control={control}
                      useFormRegisterReturn={register('regionId', {
                        // お住まいの国がその他でない場合は必須とする
                        required: required('お住まいのエリア', countryId !== '99'),
                        validate: {
                          format: (value) =>
                            validate(
                              countryId === '99' ||
                                isIn(
                                  value,
                                  regions.map((r) => r.id),
                                ),
                              'お住まいのエリア',
                            ),
                        },
                      })}
                    />
                  )}
                </div>
                <p className={styles.errorMessage}>{errors?.regionId?.message}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default MypageEditProfile;
