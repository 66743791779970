import AddCircle from '@mui/icons-material/AddCircle';
import { FC } from 'react';
import styles from './AddSkillButton.module.scss';

type Props = {
  handleClick?: () => void;
};

const AddSkillButton: FC<Props> = ({ handleClick }) => {
  return (
    <button
      type="button"
      style={{ width: '100%' }}
      className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd} `}
      onClick={handleClick}
    >
      <span className={`${styles.materialIcons}`}>
        <AddCircle fontSize="large" />
      </span>
      スキルを追加
    </button>
  );
};

AddSkillButton.defaultProps = {
  handleClick: undefined,
};

export default AddSkillButton;
