import ItemName from 'components/parts/ItemName';
import { FC } from 'react';
import { FormState, UseFormRegister } from 'react-hook-form';
import { MypageEditFormData } from 'types';
import { pattern, required } from 'utils/validation';
import styles from './Email.module.scss';

type Props = {
  register: UseFormRegister<MypageEditFormData>;
  errors: FormState<MypageEditFormData>['errors'];
};

const MypageEditEmail: FC<Props> = ({ register, errors }) => {
  return (
    <section id="mail-address" className={`${styles.panelDefault}`}>
      <h2 className={`${styles.panelDefaultTitle}`}>登録メールアドレス</h2>
      <div className={`${styles.panelDefaultContent}`}>
        <table className={`${styles.tableDefault}`}>
          <tbody>
            <tr>
              <th>
                <ItemName>メールアドレス</ItemName>
              </th>
              <td>
                <input
                  className={errors?.email?.message ? styles.errorForm : ''}
                  type="email"
                  placeholder="メールアドレスを入力してください"
                  {...register('email', {
                    required: required('メールアドレス'),
                    pattern: pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'メールアドレス'),
                  })}
                />
                <p className={styles.errorMessage}>{errors?.email?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>メールアドレス（確認）</ItemName>
              </th>
              <td>
                <input
                  className={errors?.emailConfirmation?.message ? styles.errorForm : ''}
                  type="email"
                  placeholder="入力してください"
                  {...register('emailConfirmation', {
                    pattern: pattern(/^\S+@\S+$/i, 'メールアドレス（確認）'),
                  })}
                />
                <p className={styles.errorMessage}>{errors?.emailConfirmation?.message}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default MypageEditEmail;
