import { FC } from 'react';
import JobCard from 'components/parts/JobCard';
import { JobSummary } from 'types';
import { CircularProgress } from '@mui/material';
import styles from './JobList.module.scss';

type Props = {
  jobs: JobSummary[];
  loadingForPrepare: boolean;
  grid?: boolean;
};

const JobList: FC<Props> = ({ jobs, loadingForPrepare, grid }) => {
  const result =
    jobs.length > 0 ? (
      <ul>
        {jobs.map((job) => (
          <li key={`jobcard-${job.isAds}-${job.id}`}>
            <JobCard
              id={job.id}
              isAds={job.isAds}
              companyName={job.companyName}
              title={job.title}
              salary={job.salary}
              closeDate={job.closeDate}
              url={job.url}
            />
          </li>
        ))}
      </ul>
    ) : (
      <p className={styles.empty}>一致する結果が見つかりませんでした。</p>
    );

  return (
    <div className={`${styles.jobList} ${grid ? styles.grid : ''} ${loadingForPrepare ? styles.loading : ''}`}>
      {!loadingForPrepare ? result : <CircularProgress size={60} />}
    </div>
  );
};

JobList.defaultProps = {
  grid: false,
};

export default JobList;
