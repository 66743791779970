import { FC } from 'react';
import { Link } from 'react-router-dom';
import { jobOffer } from '../../../../types';
import styles from './JobOfferBubble.module.scss';

type Props = {
  occupations: jobOffer['occupations'];
  title: string;
  images: jobOffer['images'];
};

const getImageUrl = (value: any): string => {
  const jsonString = JSON.stringify(value);
  const jsonObj = JSON.parse(jsonString);
  return jsonObj.file_path;
};

const JobOfferBubble: FC<Props> = ({ occupations, title, images }) => {
  return (
    <Link to="/jobs">
      <div className={`${styles.jobOfferBubble}`}>
        <div className={styles.jobOfferInfo}>
          <div className={styles.verticalBar} />
          {Array.isArray(occupations) &&
            occupations.map((joo) => (
              <>
                <span className={styles.jobOfferText}>
                  {Array.isArray(joo.detail) &&
                    joo.detail.map((jood) => <span className={styles.jobOfferName}>{jood.name}</span>)}
                  <span className={styles.jobOfferTitle}>{title}</span>
                </span>
              </>
            ))}
        </div>
        <span className={styles.jobOfferImage}>
          {Array.isArray(images) && images.map((joi) => <img src={getImageUrl(joi)} />)}
        </span>
      </div>
    </Link>
  );
};

export default JobOfferBubble;
