import axios from 'utils/axios';
import { JobSummary } from 'types';

const getJobsById = async (id?: string, adsId?: string): Promise<JobSummary[]> => {
  const params = new URLSearchParams({
    id: typeof id !== 'undefined' ? id : '0',
    adsId: typeof adsId !== 'undefined' ? adsId : '0',
  });

  const res = await axios
    .get('/api/company/search/jobs', {
      params,
    })
    .catch(() => {
      // noop
    });

  if (res && res.status === 200 && Array.isArray(res.data.job)) {
    const data = res.data.job.map((job: any) => {
      return {
        isAds: job.url !== '',
        id: job.id,
        companyName: job.company_name,
        title: job.title,
        salary: job.salary,
        closeDate: job.close_date,
        url: job.url === '' ? `/job/${job.id}` : job.url,
      };
    });

    return data;
  }

  return [];
};

export default getJobsById;
