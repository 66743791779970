import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MessageRoomList from 'components/parts/MessageRoomList';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import { MessageExchange, MessageFormData, MessageRoom } from 'types';
import useAuth from 'hooks/useAuth';
import useScrollTop from 'hooks/useScrollTop';
import MessageExchangeList from 'components/parts/MessageExchangeList';
import { useForm } from 'react-hook-form';
import styles from './Message.module.scss';

const Message: FC = () => {
  useAuth();
  useScrollTop();
  useCheckToken();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [selectedId, setSelectedId] = useState<number | null>(
    Number.isNaN(searchParams.get('id') as unknown as number) ? null : Number(searchParams.get('id')),
  );
  const changeSelectedId = (id: number | null) => {
    setSelectedId(id);
    const params = new URLSearchParams({
      id: String(id),
    });
    // 選択したidをURLに付与
    navigate({
      search: params.toString(),
    });
  };

  const [messageRooms, setMessageRooms] = useState<MessageRoom[]>([]);
  const [messageExchanges, setMessageExchanges] = useState<MessageExchange[]>([]);
  const [loadingForPrepare, setLoadingForPrepare] = useState(true);

  // メッセージ一覧取得
  useEffect(() => {
    const req = async () => {
      const token = getToken();
      const res = await axios.get('/api/v18/mails', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status >= 200 && Array.isArray(res.data)) {
        const messageRoomsData = res.data.map((d: any) => ({
          id: d.id,
          body: d.body,
          companyId: d.company_id,
          companyName: d.company_name,
          isUnread: d.is_unread,
          isUnreadStr: d.is_unread_str,
          accountType: d.account_type,
        }));
        setMessageRooms(messageRoomsData);
      }
    };

    req().then(() => {
      setLoadingForPrepare(false);
    });
  }, [messageExchanges, navigate]);

  useEffect(() => {
    const req = async () => {
      const token = getToken();
      const res = await axios.get(`/api/v18/mails/companies/${selectedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status >= 200 && Array.isArray(res.data)) {
        const messageExchangesData: MessageExchange[] = res.data.map((d: any) => ({
          id: d.id,
          companyId: d.company_id,
          companyName: d.company_name,
          isUserSend: d.is_user_send,
          body: d.body,
          attachedFiles: d.attached_files,
          jobOffers: d.job_offers,
          clientMsgId: d.client_msg_id,
          createdAt: d.created_at,
        }));
        setMessageExchanges(messageExchangesData);
      }
    };

    if (selectedId) {
      req();
    }
  }, [selectedId, navigate]);

  // form
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<MessageFormData>({
    defaultValues: {
      attachedFiles: [],
    },
  });

  const { attachedFiles } = watch();

  const handleChangeFiles = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null || !e.target.files[0]) {
      return;
    }

    if (attachedFiles.length >= 5) {
      alert('添付ファイルは5つまで選択できます。');
      return;
    }

    const file = e.target.files[0];

    const fileSize = attachedFiles.map((af) => af.size).reduce((p, c) => p + c, 0) + file.size;
    if (fileSize > 1073741824) {
      alert('添付ファイルは合計1GBまで選択できます。');
      return;
    }

    setValue('attachedFiles', [...attachedFiles, file]);
  };

  const handleDeleteFile = (fileIndex: number) => {
    const attachedFilesData = attachedFiles.filter((_, index) => fileIndex !== index);
    setValue('attachedFiles', [...attachedFilesData]);
  };

  const processing = useRef(false);
  const onSubmit = async (data: MessageFormData) => {
    if (!selectedId) return;

    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const token = getToken();

    const formData = new FormData();
    formData.append('company_id', String(selectedId));
    formData.append('client_msg_id', String(new Date().getTime() / 1000));
    if (data.body !== '') {
      formData.append('body', data.body);
    }
    if (data.attachedFiles.length > 0) {
      data.attachedFiles.forEach((af, index) => {
        formData.append(`attached_files[${index}]`, af);
      });
    }

    const res = await axios.post('/api/v18/mails/send', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // 処理中フラグを折る
    processing.current = false;
    if (res.status === 200 || res.status === 201) {
      const messageExchangeData: MessageExchange = {
        id: res.data.id,
        companyId: res.data.company_id,
        companyName: res.data.company_name,
        isUserSend: res.data.is_user_send,
        body: res.data.body,
        attachedFiles: res.data.attached_files,
        clientMsgId: res.data.client_msg_id,
        createdAt: res.data.created_at,
        jobOffers: res.data.jobOffers,
      };
      setMessageExchanges([...messageExchanges, messageExchangeData]);
      setValue('body', '');
      setValue('attachedFiles', []);
    } else if (res.status === 413) {
      setError('attachedFiles', {
        type: 'format',
        message: '添付ファイルの総容量は1GB未満で選択してください。',
      });
    } else if (res.status === 401) {
      navigate('/login');
    } else {
      alert('不明なエラーが発生しました。お時間をおいて再度お試しください。');
    }
  };
  const submit = handleSubmit(onSubmit);

  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--main-height-vh', `${vh}px`);
  };

  useEffect(() => {
    window.addEventListener('resize', setFillHeight);
  }, []);

  setFillHeight();

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
          <MuiLink underline="hover" color="inherit" to="/" component={Link}>
            中国語・英語を活かしてグローバル転職 JaU
          </MuiLink>
          <Typography color="text.primary">メッセージ</Typography>
        </Breadcrumbs>
        <section className={`${styles.panelDefault} ${selectedId && styles.selectedId}`}>
          <div className={styles.messageRoomList}>
            <MessageRoomList
              messageRooms={messageRooms}
              selectedId={selectedId}
              loadingForPrepare={loadingForPrepare}
              changeSelectedId={changeSelectedId}
            />
          </div>
          <div className={styles.messageExchangeList}>
            {selectedId !== null && messageExchanges.length > 0 && (
              <MessageExchangeList
                messageExchanges={messageExchanges}
                errors={errors}
                isSubmitting={isSubmitting}
                changeSelectedId={changeSelectedId}
                register={register}
                watch={watch}
                submit={submit}
                handleChangeFiles={handleChangeFiles}
                handleDeleteFile={handleDeleteFile}
              />
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default Message;
