import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Optgroup, Option } from 'types';
import axios from 'utils/axios';
import { getToken } from 'utils/token';

const useGetRequestMaster = (
  navigate: NavigateFunction,
): {
  salaries: Option[];
  industries: Optgroup[];
  occupations: Optgroup[];
  locations: Option[];
} => {
  const [salaries, setSalaries] = useState<Option[]>([]);
  const [industries, setIndustries] = useState<Optgroup[]>([]);
  const [occupations, setOccupations] = useState<Optgroup[]>([]);
  const [locations, setLocations] = useState<Option[]>([]);

  useEffect(() => {
    /** 希望年収マスター取得 */
    const reqSalary = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v14/master/request-salaries', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });

      if (res && res.status === 200) {
        const data = res.data.flat().map((salary: any) => {
          return {
            id: String(salary.id),
            name: salary.name,
          };
        });
        setSalaries(data);
      }
    };

    /** 業界マスター取得 */
    const reqIndustry = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v14/master/industries', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((industry) => {
          return {
            label: industry.name,
            options: industry.detail.map((d: any) => ({
              id: String(d.id),
              name: d.name,
            })),
          };
        });
        setIndustries(data);
      }
    };

    /** 職種マスター取得 */
    const reqOccupation = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v14/master/occupations', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((occupation) => {
          return {
            label: occupation.name,
            options: occupation.detail.map((d: any) => ({
              id: String(d.id),
              name: d.name,
            })),
          };
        });
        setOccupations(data);
      }
    };

    /** 希望勤務地マスター取得 */
    const reqLocation = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v14/master/locations', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });

      if (res && res.status === 200) {
        const data = res.data
          .map((location: any) =>
            location.detail.map((detail: any) => ({
              id: String(detail.id),
              name: location.name !== 'その他' ? `${location.name} / ${detail.name}` : detail.name,
            })),
          )
          .flat();
        setLocations(data);
      }
    };

    reqSalary();
    reqIndustry();
    reqOccupation();
    reqLocation();
  }, [navigate]);

  return {
    salaries,
    industries,
    occupations,
    locations,
  };
};

export default useGetRequestMaster;
