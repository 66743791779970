import { FC } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Header from 'components/parts/Header';
import Footer from 'components/parts/Footer';
import RegistrationFooter from 'components/parts/RegistrationFooter';
import Top from 'components/pages/Top';
import Signup from 'components/pages/Signup';
import VerifyMailSendDone from 'components/pages/VerifyMailSendDone';
import Login from 'components/pages/Login';
import ForgetPassword from 'components/pages/ForgetPassword';
import ResetMail from 'components/pages/ResetMail';
import ResetPassword from 'components/pages/ResetPassword';
import Profile from 'components/pages/Profile';
import WorkStatus from 'components/pages/WorkStatus';
import WorkHistory from 'components/pages/WorkHistory';
import ResetPasswordComplete from 'components/pages/ResetPasswordComplete';
import Skill from 'components/pages/Skill';
import Education from 'components/pages/Education';
import Mypage from 'components/pages/Mypage';
import RegistrationComplete from 'components/pages/RegistrationComplete';
import Language from 'components/pages/Language';
import Request from 'components/pages/Request';
import MypageEdit from 'components/pages/MypageEdit';
import AccountVerify from 'components/pages/AccountVerify';
import AccountVerifyDone from 'components/pages/AccountVerifyDone';
import MailVerifyDone from 'components/pages/MailVerifyDone';
import AccountVerifyFailure from 'components/pages/AccountVerifyFailure';
import Setting from 'components/pages/Setting';
import Inquiry from 'components/pages/Inquiry';
import Withdrawal from 'components/pages/Withdrawal';
import JobList from 'components/pages/JobList';
import JobDetail from 'components/pages/JobDetail';
import Message from 'components/pages/Message';
import CongratulatoryGift from 'components/pages/CongratulatoryGift';
import CongratulatoryGiftComplete from 'components/pages/CongratulatoryGiftComplete';
import Event from 'components/pages/Event';

type DefaultLayoutProps = {
  hasLogin?: boolean;
  isMypage?: boolean;
  isMessage?: boolean;
  isSimple?: boolean;
};

const DefaultLayout: FC<DefaultLayoutProps> = (props) => {
  return (
    <>
      <Header {...props} />
      <Outlet />
      <Footer {...props} />
    </>
  );
};

DefaultLayout.defaultProps = {
  hasLogin: false,
  isMypage: false,
  isMessage: false,
  isSimple: false,
};

const RegistrationLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <RegistrationFooter />
    </>
  );
};

const App: FC = () => {
  return (
    <>
      <Routes>
        <Route element={<RegistrationLayout />}>
          <Route path="signup" element={<Signup />} />
          <Route path="verify-mail/send/done" element={<VerifyMailSendDone />} />
          <Route path="account-verify" element={<AccountVerify />} />
          <Route path="account-verify/done" element={<AccountVerifyDone />} />
          <Route path="mail-verify/done" element={<MailVerifyDone />} />
          <Route path="account-verify/failure" element={<AccountVerifyFailure />} />
          <Route path="profile" element={<Profile />} />
          <Route path="work-status" element={<WorkStatus />} />
          <Route path="work-history" element={<WorkHistory />} />
          <Route path="skill" element={<Skill />} />
          <Route path="education" element={<Education />} />
          <Route path="language" element={<Language />} />
          <Route path="request" element={<Request />} />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="forget-password" element={<ForgetPassword />} />
          <Route path="reset-mail" element={<ResetMail />} />
          <Route path="reset-password/:userId/:token" element={<ResetPassword />} />
          <Route path="reset-password/complete" element={<ResetPasswordComplete />} />
        </Route>
        <Route element={<DefaultLayout isMypage />}>
          <Route path="registration-complete" element={<RegistrationComplete />} />
          <Route path="mypage" element={<Mypage />} />
          <Route path="/mypage/edit" element={<MypageEdit />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/inquiry" element={<Inquiry />} />
          <Route path="/withdrawal" element={<Withdrawal />} />
          <Route path="/congratulatory-gift" element={<CongratulatoryGift />} />
          <Route path="/congratulatory-gift/complete" element={<CongratulatoryGiftComplete />} />
        </Route>
        <Route element={<DefaultLayout isMypage hasLogin isSimple />}>
          <Route path="/" element={<Top />} />
          <Route path="/event/20230831" element={<Event eventIdentifier="20230831" />} />
          <Route path="/jobs" element={<JobList />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Route>
        <Route element={<DefaultLayout isMypage isSimple />}>
          <Route path="/job/:id" element={<JobDetail />} />
        </Route>
        <Route element={<DefaultLayout isMypage isMessage />}>
          <Route path="/messages" element={<Message />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
