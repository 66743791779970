import { Option } from 'types';

// 生年月日用の年のオプション
export const birthYearOptions = (): Option[] => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;
  const endYear = currentYear - 18;
  return [...Array(endYear - startYear + 1)].map((_, index) => ({
    id: `${startYear + index}`,
    name: `${startYear + index}`,
  }));
};

// 職歴在籍期間、卒業年月（予定）用の年のオプション
export const workOrGraduationYearOptions = (): Option[] => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;
  const endYear = currentYear + 1; // 来年まで
  return [...Array(endYear - startYear + 1)].map((_, index) => ({
    id: `${startYear + index}`,
    name: `${startYear + index}`,
  }));
};

// 入社予定日用の年のオプション
export const joiningYearOptions = (): Option[] => {
  const currentYear = new Date().getFullYear();
  const startYear = 2021; // サービス開始年
  const endYear = currentYear + 5; // 5年後まで
  return [...Array(endYear - startYear + 1)].map((_, index) => ({
    id: `${startYear + index}`,
    name: `${startYear + index}`,
  }));
};

export const monthOptions: Option[] = [...Array(12)].map((_, index) => ({
  id: `${index + 1}`,
  name: `${index + 1}`,
}));

export const dayOptions: Option[] = [...Array(31)].map((_, index) => ({
  id: `${index + 1}`,
  name: `${index + 1}`,
}));

export const countryNumberOptions: Option[] = [
  {
    id: '81',
    name: '日本',
  },
  {
    id: '86',
    name: '中国',
  },
  {
    id: '82',
    name: '韓国',
  },
  {
    id: '886',
    name: '台湾',
  },
  {
    id: '852',
    name: '香港',
  },
  {
    id: '1',
    name: 'アメリカ',
  },
  {
    id: '44',
    name: 'イギリス',
  },
  {
    id: '49',
    name: 'ドイツ',
  },
];
