import { FC } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { SearchJobFormData } from 'types';
import useGetRegionMaster from 'hooks/Master/useGetRegionMaster';
import useFormSearchJob from 'hooks/SearchJob/useFormSearchJob';
import { isIn, required, validate } from 'utils/validation';
import SelectDropdown from 'components/parts/SelectDropdown';
import styles from './SearchJob.module.scss';

type Props = {
  onSubmit: SubmitHandler<SearchJobFormData>;
  hasSubColumns?: boolean;
};

const SearchJob: FC<Props> = ({ onSubmit, hasSubColumns }) => {
  const location = useLocation();

  const { register, handleSubmit, control, errors } = useFormSearchJob(location.state);

  // マスター情報取得
  const regionMaster = useGetRegionMaster();
  const regionMasterJa = [{ id: '', name: '勤務地' }].concat(regionMaster.regions.filter((r) => r.countryId === '1'));

  return (
    <form className={styles.searchJob} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.main}>
        <div className={styles.regionCont}>
          {regionMasterJa.length > 1 ? (
            <SelectDropdown
              className={`${styles.region} ${errors?.regionId?.message ? styles.errorForm : ''}`}
              options={regionMasterJa}
              control={control}
              useFormRegisterReturn={register('regionId', {
                validate: {
                  format: (value) =>
                    validate(
                      isIn(
                        value,
                        regionMasterJa.map((e) => e.id),
                      ),
                      '勤務地',
                    ),
                },
              })}
            />
          ) : (
            <CircularProgress size={60} />
          )}
          <p className={styles.errorMessage}>{errors?.regionId?.message}</p>
        </div>
        <div className={styles.freeword}>
          <input className="" type="text" placeholder="求人、企業を検索" {...register('freeword', {})} />
        </div>
        <button type="submit" className={styles.submit}>
          検索
        </button>
      </div>
      {hasSubColumns && (
        <div className={styles.left}>
          <div className={`${styles.periodCont} ${styles.inputGroup}`}>
            <label>掲載日</label>
            <SelectDropdown
              className={`${styles.period} ${errors?.period?.message ? styles.errorForm : ''}`}
              style="red"
              options={[
                { id: '1', name: '24h' },
                { id: '2', name: '3日以内' },
                { id: '3', name: '7日以内' },
                { id: '4', name: '14日以内' },
                { id: '', name: 'すべて' },
              ]}
              control={control}
              useFormRegisterReturn={register('period', {
                validate: {
                  format: (value) => validate(isIn(value, ['1', '2', '3', '4', '']), '掲載日'),
                },
              })}
            />
          </div>
          <div className={`${styles.salaryCont} ${styles.inputGroup}`}>
            <label>想定年収</label>
            <SelectDropdown
              className={`${styles.salary} ${errors?.salary?.message ? styles.errorForm : ''}`}
              style="red"
              options={[
                { id: '1', name: '300万円～' },
                { id: '2', name: '400万円～' },
                { id: '3', name: '500万円～' },
                { id: '4', name: '600万円～' },
                { id: '5', name: '700万円～' },
                { id: '6', name: '800万円～' },
                { id: '7', name: '900万円～' },
                { id: '8', name: '1000万円～' },
              ]}
              control={control}
              useFormRegisterReturn={register('salary', {
                required: required('想定年収'),
                validate: {
                  format: (value) => validate(isIn(value, ['1', '2', '3', '4', '5', '6', '7', '8']), '想定年収'),
                },
              })}
            />
          </div>
        </div>
      )}
    </form>
  );
};

SearchJob.defaultProps = {
  hasSubColumns: false,
};

export default SearchJob;
