import { FC } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CongratulatoryGiftBanner from 'components/parts/CongratulatoryGiftBanner';
import { useCheckToken } from 'hooks/useCheckToken';
import styles from './Setting.module.scss';

const Setting: FC = () => {
  useCheckToken();
  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
          <MuiLink underline="hover" color="inherit" to="/" component={Link}>
            中国語・英語を活かしてグローバル転職 JaU
          </MuiLink>
          <Typography color="text.primary">設定</Typography>
        </Breadcrumbs>
        <h1 className={`${styles.titleMain}`}>設定</h1>
        <section className={`${styles.panelDefault}`}>
          {/* 運営からのお知らせ画面が作成されるまでコメントアウト */}
          {/* <div className={`${styles.panelDefaultTitle}`}>運営からのお知らせ</div> */}
          <a
            className={`${styles.panelDefaultTitle}`}
            href="https://www.clara.co.jp/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
          <a className={`${styles.panelDefaultTitle}`} href="https://jiayou.jp/terms" target="_blank" rel="noreferrer">
            利用規約
          </a>
          <Link className={`${styles.panelDefaultTitle}`} to="/inquiry">
            運営へお問い合わせ
          </Link>
        </section>
        <div className={styles.congratulatoryGiftBanner}>
          <CongratulatoryGiftBanner />
        </div>
      </div>
    </main>
  );
};

export default Setting;
