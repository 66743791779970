import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BackButton.module.scss';

const BackButton = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <button type="button" className={styles.buttonReturn} onClick={goBack}>
      戻る
    </button>
  );
};

export default BackButton;
