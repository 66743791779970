import { useForm } from 'react-hook-form';
import { SearchJobFormData } from 'types';

const useFormSearchJob = (data?: SearchJobFormData) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<SearchJobFormData>({
    mode: 'onChange',
    defaultValues: {
      regionId: data ? data.regionId : '',
      freeword: data ? data.freeword : '',
      period: data ? data.period : '',
      salary: data ? data.salary : '1',
    },
  });

  return {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    errors,
    isValid,
  };
};

export default useFormSearchJob;
