import { FC, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import styles from './PageTop.module.scss';

const PageTop: FC = () => {
  const scrollTop: MouseEventHandler = (e) => {
    e.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Link className={styles.pageTop} to="#top" onClick={scrollTop}>
      <p>Page Top</p>
    </Link>
  );
};

export default PageTop;
