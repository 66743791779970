import { FC, useEffect, useRef } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import useAuth from 'hooks/useAuth';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import useGetProfileMaster from 'hooks/Master/useGetProfieMaster';
import useGetWorkStatusMaster from 'hooks/Master/useGetWorkStatusMaster';
import useGetWorkHistoryMaster from 'hooks/Master/useGetWorkHistoryMaster';
import useGetSkillMaster from 'hooks/Master/useGetSkillMaster';
import useGetEducationMaster from 'hooks/Master/useGetEducationMaster';
import useGetLanguageMaster from 'hooks/Master/useGetLanguageMaster';
import useGetRequestMaster from 'hooks/Master/useGetRequestMaster';
import MypageEditProfile from 'components/parts/MypageEdit/Profile';
import MypageEditWorkStatus from 'components/parts/MypageEdit/WorkStatus';
import MypageEditWorkHistory from 'components/parts/MypageEdit/WorkHistory';
import MypageEditSkill from 'components/parts/MypageEdit/Skill';
import MypageEditEducation from 'components/parts/MypageEdit/Education';
import MypageEditLanguage from 'components/parts/MypageEdit/Language';
import MypageEditQualification from 'components/parts/MypageEdit/Qualification';
import MypageEditRequest from 'components/parts/MypageEdit/Request';
import MypageEditEmail from 'components/parts/MypageEdit/Email';
import useForm from 'hooks/MypageEdit/useForm';
import { useFieldArray } from 'react-hook-form';
import { MypageEditFormData } from 'types';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import updateWorkHistories from 'requests/MypageEdit/updateWorkHistories';
import updateProfile from 'requests/MypageEdit/updateProfile';
import updateSkill from 'requests/MypageEdit/updateSkill';
import updateRequest from 'requests/MypageEdit/updateRequest';
import useScrollTop from 'hooks/useScrollTop';
import useHashFragment from 'hooks/useHashFragment';
import useSetInitial from 'hooks/MypageEdit/useSetInitial';
import { CircularProgress } from '@mui/material';
import CongratulatoryGiftBanner from 'components/parts/CongratulatoryGiftBanner';
import useGetUser from 'hooks/useGetUser';
import styles from './MypageEdit.module.scss';

const MypageEdit: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { mutate: getUserMutate } = useGetUser();
  useScrollTop();
  useHashFragment();
  useCheckToken();

  const { register, handleSubmit, control, watch, setValue, setError, clearErrors, errors, isValid } = useForm();
  const workHistoriesField = useFieldArray({
    name: 'workHistories',
    keyName: 'key',
    control,
  });
  const skillsField = useFieldArray({
    name: 'skills',
    keyName: 'key',
    control,
  });

  // 初期値セット
  const { loadingForPrepare, email } = useSetInitial(setValue);

  // マスター情報取得
  const countryId = watch('countryId');
  const profileMaster = useGetProfileMaster(navigate);
  const workStatusMaster = useGetWorkStatusMaster(navigate);
  const workHistoryMaster = useGetWorkHistoryMaster(navigate);
  const skillMaster = useGetSkillMaster(navigate);
  const educationMaster = useGetEducationMaster(navigate);
  const languageId = watch('languageId');
  const acquireLanguage = watch('acquireLanguage');
  const languageMaster = useGetLanguageMaster(navigate, setValue, languageId, acquireLanguage);
  const requestMaster = useGetRequestMaster(navigate);

  const workHistories = watch('workHistories');
  const educationsId = watch('educationsId');

  const listener = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  }, []);

  const processing = useRef(false);
  const onSubmit = async (data: MypageEditFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const token = getToken();
    // プロフィール(電話番号、メールアドレスを含む)、学歴
    const { result, isEmailUpdated } = await updateProfile(setError, auth.id, token, data, email);
    let r = result;
    if (r) {
      // 職務経歴
      r = await updateWorkHistories(setError, auth.id, token, data);
    }
    if (r) {
      // スキル、語学、資格
      r = await updateSkill(setError, auth.id, token, data, skillMaster.skillLevels);
    }
    if (r) {
      // 希望条件
      r = await updateRequest(setError, auth.id, token, data);
    }

    // 処理中フラグを折る
    processing.current = false;
    if (r) {
      // ヘッダーの表示名取得のキャッシュを削除する.
      getUserMutate();
      window.removeEventListener('beforeunload', listener);
      if (isEmailUpdated) {
        // メアド追加認証要
        navigate('/mypage', { state: true });
      } else {
        // メアド追加認証不要
        navigate('/mypage');
      }
    }
  };

  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <form className={`${styles.container}`} onSubmit={handleSubmit(onSubmit)}>
          <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
            <MuiLink underline="hover" color="inherit" to="/" component={Link}>
              中国語・英語を活かしてグローバル転職 JaU
            </MuiLink>
            <MuiLink underline="hover" color="inherit" to="/mypage" component={Link}>
              登録情報
            </MuiLink>
            <Typography color="text.primary">登録情報編集</Typography>
          </Breadcrumbs>
          {!auth.isValid && <p className={styles.em}>※の入力完了後、求人への応募とスカウトを受けることができます。</p>}
          <h1 className={`${styles.titleMain}`}>登録情報編集</h1>
          {!loadingForPrepare ? (
            <>
              {/* 基本情報 */}
              <MypageEditProfile
                {...profileMaster}
                countryId={countryId}
                control={control}
                loadingInitial={loadingForPrepare}
                register={register}
                setValue={setValue}
                clearErrors={clearErrors}
                errors={errors}
              />
              {/* 学歴 */}
              <MypageEditEducation
                {...educationMaster}
                control={control}
                educationsId={educationsId}
                loadingInitial={loadingForPrepare}
                register={register}
                errors={errors}
              />
              {/* 就業状況 */}
              <MypageEditWorkStatus
                {...workStatusMaster}
                loadingInitial={loadingForPrepare}
                register={register}
                errors={errors}
              />
              {/* 職務情報 */}
              <MypageEditWorkHistory
                {...workHistoryMaster}
                workHistories={workHistories}
                loadingInitial={loadingForPrepare}
                control={control}
                register={register}
                watch={watch}
                {...workHistoriesField}
                errors={errors}
              />
              {/* スキル */}
              <MypageEditSkill
                {...skillMaster}
                skillsData={watch('skills')}
                control={control}
                loadingInitial={loadingForPrepare}
                register={register}
                {...skillsField}
                errors={errors}
              />
              {/* 語学 */}
              <MypageEditLanguage
                {...languageMaster}
                loadingInitial={loadingForPrepare}
                register={register}
                control={control}
                errors={errors}
              />
              {/* 資格 */}
              <MypageEditQualification loadingInitial={loadingForPrepare} control={control} errors={errors} />
              {/* 希望情報 */}
              <MypageEditRequest
                {...requestMaster}
                loadingInitial={loadingForPrepare}
                control={control}
                register={register}
                errors={errors}
              />
              {/* 登録メールアドレス */}
              <MypageEditEmail register={register} errors={errors} />
            </>
          ) : (
            <div className={styles.loadingField}>
              <CircularProgress size={80} />
            </div>
          )}
          {!loadingForPrepare && (
            <button
              type="submit"
              className={`${styles.buttonRound} ${isValid ? styles.buttonPrimary : styles.buttonPrimaryDisabled} ${
                styles.buttonMd
              } `}
              disabled={!isValid}
            >
              登録情報の更新
              <span className={`${styles.materialIcons}`}>
                <ArrowForwardIosRoundedIcon />
              </span>
            </button>
          )}
          <div className={styles.congratulatoryGiftBanner}>
            <CongratulatoryGiftBanner />
          </div>
        </form>
      </main>
    </>
  );
};

export default MypageEdit;
