import { FC } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import useAuth from 'hooks/useAuth';
import styles from './AccountVerifyDone.module.scss';

const AccountVerifyDone: FC = () => {
  useAuth();

  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
            <MuiLink underline="hover" color="inherit" to="/" component={Link}>
              中国語・英語を活かしてグローバル転職 JaU
            </MuiLink>
            <Typography color="text.primary">アカウント作成完了</Typography>
          </Breadcrumbs>
          <h1 className={`${styles.titleMain}`}>アカウント作成</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>アカウント作成完了</h2>
            <div className={`${styles.panelDefaultContent}`}>
              <p className={`${styles.leadContent}`}>アカウント作成が完了しました</p>
              <p className={`${styles.followContent} ${styles.marginBottomLg}`}>
                次に、あなたのプロフィールを登録します。
                <br />
                <b className={`${styles.emphasis}`}>3</b>分程で完了します。
              </p>
            </div>
            <div className={`${styles.buttonGroup}`}>
              <Link to="/profile" className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}>
                スタート
                <span className={`${styles.materialIcons}`}>
                  <ArrowForwardIosRoundedIcon />
                </span>
              </Link>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default AccountVerifyDone;
