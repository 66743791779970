import { FC } from 'react';
import CongratulatoryGiftBannerPC from 'images/congratulatory_gift_banner_pc.png';
import CongratulatoryGiftBannerSP from 'images/congratulatory_gift_banner_sp.png';
import { Link } from 'react-router-dom';
import styles from './CongratulatoryGiftBanner.module.scss';

const CongratulatoryGiftBanner: FC = () => {
  return (
    <Link to="/congratulatory-gift" className={styles.congratulatoryGiftBanner}>
      <img className={styles.pc} src={CongratulatoryGiftBannerPC} />
      <img className={styles.sp} src={CongratulatoryGiftBannerSP} />
    </Link>
  );
};

export default CongratulatoryGiftBanner;
