import { FC } from 'react';
import FileIcon from 'images/messages/file_icon.svg';
import styles from './IconBubble.module.scss';

type Props = {
  filename: string;
  url: string;
  isUserSend: boolean;
  isImage: boolean;
};

const IconBubble: FC<Props> = ({ filename, url, isUserSend, isImage }) => {
  return (
    <div className={`${styles.iconBubble} ${isUserSend && styles.user}`}>
      {isImage ? (
        <div className={styles.imageWrapper}>
          <img src={url} alt={filename} />
        </div>
      ) : (
        <div className={styles.iconWrapper}>
          <img src={FileIcon} alt={filename} />
        </div>
      )}
      <div className={styles.filenames}>
        <div>{filename}</div>
      </div>
    </div>
  );
};

export default IconBubble;
