import { FC, useRef } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import axios from 'utils/axios';
import { minLength, pattern, required } from 'utils/validation';
import useCheckNotLogin from 'hooks/useCheckNotLogin';
import useScrollTop from 'hooks/useScrollTop';
import styles from './Signup.module.scss';

type FormData = {
  email: string;
  password: string;
  accepted: boolean;
  message: string;
};

type SignupProps = {
  eventIdentifier?: string;
};

const Signup: FC<SignupProps> = ({ eventIdentifier = '' }) => {
  // ログイン中であればマイページ登録情報に飛ばす
  useCheckNotLogin();
  useScrollTop();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  const processing = useRef(false);
  const navigate = useNavigate();
  const onSubmit = async (data: FormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const paramsCheckEmail = new URLSearchParams({
      email: data.email,
    });
    const resCheckEmail = await axios.post('/api/v10/user/check-email', paramsCheckEmail);

    if (resCheckEmail.data !== 1) {
      // メールアドレスが使用できないエラー表示
      setError('email', {
        type: 'unavailable',
        message: '登録に失敗しました。',
      });

      // 処理中フラグを折る
      processing.current = false;
      return;
    }

    const params = new URLSearchParams({
      email: data.email,
      password: data.password,
      device_type: '3',
      event_identifier: eventIdentifier,
    });
    const resPreRegister = await axios.post('/api/v10/user/pre-register', params);

    // 処理中フラグを折る
    processing.current = false;

    if (resPreRegister.status === 200) {
      // 認証メール送信完了画面に遷移.
      navigate('/verify-mail/send/done');
    }
  };

  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          {!eventIdentifier && (
            <>
              <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
                <MuiLink underline="hover" color="inherit" to="/" component={Link}>
                  中国語・英語を活かしてグローバル転職 JaU
                </MuiLink>
                <Typography color="text.primary">新規会員登録</Typography>
              </Breadcrumbs>
            </>
          )}
          <h1 className={`${styles.titleMain}`}>新規会員登録（無料）</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>JaUに会員登録する</h2>
            <form className={`${styles.panelDefaultContent}`} onSubmit={handleSubmit(onSubmit)} noValidate>
              <table className={`${styles.tableDefault}`}>
                <tr>
                  <th>メールアドレス</th>
                  <td>
                    <input
                      className={errors?.email?.message ? styles.errorForm : ''}
                      type="email"
                      placeholder="メールアドレスを入力してください"
                      {...register('email', {
                        required: required('メールアドレス'),
                        pattern: pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'メールアドレス'),
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.email?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>新しいパスワード</th>
                  <td>
                    <input
                      className={errors?.password?.message ? styles.errorForm : ''}
                      type="password"
                      placeholder="パスワードを入力してください"
                      {...register('password', {
                        required: required('新しいパスワード'),
                        minLength: minLength(8, '新しいパスワード', 'は半角英数字8文字以上を入力してください。'),
                        pattern: {
                          value: /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d!@#$%^&*()_+,.\\/;':-]{8,}$/,
                          message: '新しいパスワードは半角英数字8文字以上を入力してください。',
                        },
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.password?.message}</p>
                  </td>
                </tr>
              </table>
              <div className={`${styles.ruleSect}`}>
                <label htmlFor="accepted">
                  <input
                    id="accepted"
                    className={`${styles.checkboxDefault}`}
                    type="checkbox"
                    {...register('accepted', {
                      required: {
                        value: true,
                        message: '利用規約・個人情報の保護法に同意していただく必要があります',
                      },
                    })}
                  />
                  <span className={`${styles.checkboxDefaultText}`}>
                    <a href="https://jiayou.jp/terms" target="_blank" rel="noreferrer">
                      利用規約
                    </a>
                    ・
                    <a href="https://www.clara.co.jp/privacy-policy/" target="_blank" rel="noreferrer">
                      個人情報の保護法
                    </a>
                    に同意する
                  </span>
                </label>
                <p className={styles.errorMessage}>{errors?.accepted?.message}</p>
              </div>
              <button type="submit" className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}>
                新規登録（無料）
              </button>
            </form>
          </section>
        </div>
      </main>
    </>
  );
};

Signup.defaultProps = {
  eventIdentifier: '',
};

export default Signup;
