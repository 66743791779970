import { FC } from 'react';
import { components, createFilter } from 'react-select';
import CreatableSelect from 'react-select/async-creatable';
import { Optgroup, Option } from 'types';
import styles from './AsyncCreatableSelectInput.module.scss';

type Props = {
  className?: string;
  value?: any;
  options?: Option[];
  optgroups?: Optgroup[];
  placeholder?: string;
  loadOptions?: any;
  isMulti?: boolean;
  onChange?: any;
};

const DropdownIndicator = (props: any) => {
  return (
    <div>
      <components.DropdownIndicator {...props}>
        <div className={styles.dropdownIndicator} />
      </components.DropdownIndicator>
    </div>
  );
};

const AsyncCreatableSelectInput: FC<Props> = ({
  className,
  value,
  options,
  optgroups,
  placeholder,
  loadOptions,
  isMulti,
  onChange,
}) => {
  const selectOptions =
    options && options.length > 0
      ? options?.map((o) => ({
          value: o.id,
          label: o.name,
        }))
      : optgroups?.map((og) => ({
          label: og.label,
          options: og.options.map((o) => ({
            value: o.id,
            label: o.name,
          })),
        }));

  const formatOptionLabel = (data: any) => {
    return <div>{data.label && data.label.replace(/#[0-9]*/g, '')}</div>;
  };

  return (
    <div>
      <CreatableSelect
        className={className}
        placeholder={placeholder}
        isClearable
        isMulti={isMulti}
        options={selectOptions}
        components={{ DropdownIndicator }}
        onChange={onChange}
        styles={{
          control: (defaultStyles) => ({
            ...defaultStyles,
            backgroundColor: 'initial',
          }),
          placeholder: (defaultStyles) => ({
            ...defaultStyles,
          }),
          dropdownIndicator: (defaultStyles) => ({
            ...defaultStyles,
            display: 'none',
          }),
          indicatorSeparator: (defaultStyles) => ({
            ...defaultStyles,
            display: 'none',
          }),
        }}
        value={value}
        filterOption={createFilter({
          ignoreAccents: false,
        })}
        formatCreateLabel={(inputValue) => `${inputValue}を入力する`}
        loadOptions={loadOptions}
        noOptionsMessage={() => `入力してください`}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
};

AsyncCreatableSelectInput.defaultProps = {
  className: undefined,
  value: undefined,
  placeholder: undefined,
  options: [],
  optgroups: [],
  loadOptions: undefined,
  isMulti: false,
  onChange: undefined,
};

export default AsyncCreatableSelectInput;
