import { FC, useRef } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useForm } from 'react-hook-form';
import axios from 'utils/axios';
import { minLength, required } from 'utils/validation';
import useCheckNotLogin from 'hooks/useCheckNotLogin';
import useScrollTop from 'hooks/useScrollTop';
import styles from './ResetPassword.module.scss';

type FormData = {
  password: string;
  passwordConfirmation: string;
};

const ResetPassword: FC = () => {
  // ログイン中であればマイページ登録情報に飛ばす
  useCheckNotLogin();
  useScrollTop();

  const navigate = useNavigate();

  const pathParams = useParams();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>();

  const processing = useRef(false);
  const onSubmit = async (data: FormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    if (data.password !== data.passwordConfirmation) {
      // 新しいパスワードとパスワード（確認）が異なる場合
      setError('passwordConfirmation', {
        type: 'inconsistent',
        message: 'パスワード（確認）が一致しません。',
      });

      // 処理中フラグを折る
      processing.current = false;
      return;
    }

    const resEmail = await axios.get(`/api/v8/user/reset/password/${pathParams.userId}/${pathParams.token}`);

    const params = new URLSearchParams({
      password: data.password,
      password_confirmation: data.passwordConfirmation,
      email: resEmail.data.email,
      token: pathParams.token as string,
      device_type: '3',
    });

    const res = await axios.post('/api/v8/user/reset/password', params);

    // 処理中フラグを折る
    processing.current = false;

    if (res.status === 200) {
      navigate('/reset-password/complete');
    }
  };

  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
            <MuiLink underline="hover" color="inherit" to="/" component={Link}>
              中国語・英語を活かしてグローバル転職 JaU
            </MuiLink>
            <MuiLink underline="hover" color="inherit" to="/login" component={Link}>
              ログイン
            </MuiLink>
            <Typography color="text.primary">パスワード再設定</Typography>
          </Breadcrumbs>
          <h1 className={`${styles.titleMain}`}>パスワード再設定</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>新しいパスワードの設定</h2>
            <form onSubmit={handleSubmit(onSubmit)} className={`${styles.panelDefaultContent}`}>
              <p className={`${styles.marginBottomMd}`}>新しいパスワードを設定してください。</p>
              <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
                <tr>
                  <th>新しいパスワード</th>
                  <td>
                    <input
                      className={errors?.password?.message ? styles.errorForm : ''}
                      type="password"
                      placeholder="新しいパスワードを入力してください"
                      {...register('password', {
                        required: required('新しいパスワード'),
                        minLength: minLength(8, '新しいパスワード', 'は半角英数字8文字以上を入力してください。'),
                        pattern: {
                          value: /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d!@#$%^&*()_+,.\\/;':-]{8,}$/,
                          message: '新しいパスワードは半角英数字8文字以上を入力してください。',
                        },
                      })}
                    />
                    <span className={`${styles.marginLeft8}`}>※ 半角英数8文字以上</span>
                    <p className={styles.errorMessage}>{errors?.password?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>パスワード（確認）</th>
                  <td>
                    <input
                      className={errors?.passwordConfirmation?.message ? styles.errorForm : ''}
                      type="password"
                      placeholder="パスワード（確認）を入力してください"
                      {...register('passwordConfirmation', {
                        required: required('パスワード（確認）'),
                        minLength: minLength(8, 'パスワード（確認）', 'は半角英数字8文字以上を入力してください。'),
                        pattern: {
                          value: /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d!@#$%^&*()_+,.\\/;':-]{8,}$/,
                          message: 'パスワード（確認）は半角英数字8文字以上を入力してください。',
                        },
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.passwordConfirmation?.message}</p>
                  </td>
                </tr>
              </table>
              <button type="submit" className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}>
                パスワードを設定
                <span className={`${styles.materialIcons}`}>
                  <ArrowForwardIosRoundedIcon />
                </span>
              </button>
            </form>
          </section>
        </div>
      </main>
    </>
  );
};

export default ResetPassword;
