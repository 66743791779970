import NextButton from 'components/parts/NextButton';
import Select from 'components/parts/Select';
import useGetLanguageMaster from 'hooks/Master/useGetLanguageMaster';
import useAuth from 'hooks/useAuth';
import { FC, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import { isIn, required, validate } from 'utils/validation';
import ProgressBar from 'components/parts/ProgressBar';
import BackButton from 'components/parts/BackButton';
import useScrollTop from 'hooks/useScrollTop';
import ItemName from 'components/parts/ItemName';
import styles from './Language.module.scss';

type FormData = {
  languageId: string;
  acquireLanguage: string[];
  testJlptGradeId: string;
  testHskGradeId: string;
  testChineseGradeId: string;
  toeicScore: string;
  toeflScore: string;
};

const Language: FC = () => {
  // 認証制御
  const auth = useAuth();
  useScrollTop();
  useCheckToken();

  const navigate = useNavigate();

  const {
    watch,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  // 初期値セット
  useEffect(() => {
    const req = async () => {
      const token = getToken();
      const res = await axios.get('/api/v14/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const skills = res.data.skills?.skill;

      if (skills) {
        if (typeof skills?.language_id?.id === 'number') {
          setValue('languageId', String(skills.language_id.id), {
            shouldValidate: true,
          });
        }
      }
    };

    if (auth.id) {
      req();
    }
  }, [auth.id, setValue, navigate]);

  const processing = useRef(false);
  const onSubmit = async (data: FormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const params: Record<string, any> = {
      skill: {
        language_id: data.languageId,
      },
    };

    const token = getToken();
    const res = await axios.put('/api/v14/users/skills', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // 処理中フラグを折る
    processing.current = false;
    if (res.status === 200 || res.status === 201) {
      // 次のページに飛ばす (スキル)
      navigate('/skill');
    }
  };

  const languageId = watch('languageId');
  const acquireLanguage = watch('acquireLanguage');
  const { languages } = useGetLanguageMaster(navigate, setValue, languageId, acquireLanguage);

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <h1 className={`${styles.titleMain}`}>新規会員登録（無料）</h1>
        <div className={styles.progressBar}>
          <ProgressBar colorizedNumber={5} />
        </div>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>語学</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={`${styles.panelDefaultContent}`}>
            <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
              <tbody>
                <tr>
                  <th>
                    <ItemName required>母国語</ItemName>
                  </th>
                  <td>
                    <Select
                      className={errors?.languageId?.message ? styles.errorForm : ''}
                      placeholder="選択してください"
                      options={languages}
                      control={control}
                      useFormRegisterReturn={register('languageId', {
                        required: required('母国語'),
                        validate: {
                          format: (value) =>
                            validate(
                              isIn(
                                value,
                                languages.map((l) => l.id),
                              ),
                              '母国語',
                            ),
                        },
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.languageId?.message}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={`${styles.buttonGroup}`}>
              <NextButton isValid={isValid} />
              <BackButton />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default Language;
