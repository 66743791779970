import { FC } from 'react';
import styles from './RegistrationFooter.module.scss';

const RegistrationFooter: FC = () => {
  return (
    <>
      <footer className={`${styles.footer}`}>
        <div className={`${styles.footerContainer}`}>
          <div className={`${styles.footerBottom}`}>
            <small>© 2022 CLARA, Inc.</small>
          </div>
        </div>
      </footer>
    </>
  );
};

export default RegistrationFooter;
