import MuiLink from '@mui/material/Link';
import { Breadcrumbs, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ItemName from 'components/parts/ItemName';
import Select from 'components/parts/Select';
import useGetUser from 'hooks/useGetUser';
import { useForm } from 'react-hook-form';
import { CongratulatoryGiftFormData, MessageRoom, Option } from 'types';
import useAuth from 'hooks/useAuth';
import useScrollTop from 'hooks/useScrollTop';
import { dayOptions, monthOptions, joiningYearOptions } from 'utils/options';
import Textarea from 'components/parts/Textarea';
import NextButton from 'components/parts/NextButton';
import { getToken } from 'utils/token';
import { useCheckToken } from 'hooks/useCheckToken';
import axios from 'utils/axios';
import { isIn, required, validate } from 'utils/validation';
import styles from './CongratulatoryGift.module.scss';

const CongratulatoryGift: FC = () => {
  useAuth();
  useScrollTop();
  useCheckToken();

  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('id');

  const navigate = useNavigate();

  const [user, setUser] = useState<any>(null);
  const { data: userData, isLoading } = useGetUser();
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (userData && 'data' in userData) {
      setUser(userData.data);
    }
  }, [userData, isLoading]);

  const [companies, setCompanies] = useState<MessageRoom[]>([]);
  const [companyOptions, setCompanyOptions] = useState<Option[]>([]);
  const [loadingForPrepare, setLoadingForPrepare] = useState(true);
  useEffect(() => {
    const req = async () => {
      const token = getToken();
      const res = await axios.get('/api/v18/mails', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status >= 200 && Array.isArray(res.data)) {
        const companiesData = res.data
          .filter((d: any) => d.company_id !== 1)
          .map((d: any) => ({
            id: d.id,
            body: d.body,
            companyId: d.company_id,
            companyName: d.company_name,
            isUnread: d.is_unread,
            isUnreadStr: d.is_unread_str,
            accountType: d.account_type,
          }));
        setCompanies(companiesData);

        const companyOptionsData = companiesData.map((d) => ({
          id: String(d.companyId),
          name: d.companyName,
        }));
        setCompanyOptions(companyOptionsData);
      }
    };

    req().then(() => {
      setLoadingForPrepare(false);
    });
  }, [navigate]);

  const date = new Date();
  const {
    register,
    control,
    watch,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<CongratulatoryGiftFormData>({
    mode: 'onChange',
    defaultValues: {
      companyId: companyId ?? undefined,
      entryYear: String(date.getFullYear()),
      entryMonth: String(date.getMonth() + 2),
      entryDay: '1',
    },
  });

  const processing = useRef(false);
  const onSubmit = async (data: CongratulatoryGiftFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    let result = true;
    const token = getToken();
    const entryDate = new Date(`${data.entryYear}/${data.entryMonth}/${data.entryDay}`);
    if (
      !(
        entryDate.getFullYear() === Number(data.entryYear) &&
        entryDate.getMonth() + 1 === Number(data.entryMonth) &&
        entryDate.getDate() === Number(data.entryDay)
      )
    ) {
      // 入社予定日形式エラー
      setError('entryYear', {
        type: 'format',
        message: '入社予定日は正しい形式で入力してください。',
      });
      setError('entryMonth', {
        type: 'format',
        message: '入社予定日は正しい形式で入力してください。',
      });
      setError('entryDay', {
        type: 'format',
        message: '入社予定日は正しい形式で入力してください。',
      });
      result = false;
    }

    if (!result) {
      // 処理中フラグを折る
      processing.current = false;
      return;
    }

    const params = {
      company_id: data.companyId,
      recruiter: data.recruiter,
      entry_date: `${data.entryYear}/${data.entryMonth}/${data.entryDay}`,
      salary: data.salary,
      offering_company_name: data.offeringCompanyName ?? '',
      comment: data.comment,
    };
    const res = await axios.post('/api/v14/users/offer', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200 || res.status === 201) {
      // 処理中フラグを折る
      processing.current = false;
      // 次のページに飛ばす
      navigate('/congratulatory-gift/complete');
    }
  };

  const isShowOfferingCompanyName = () => {
    const selectedCompany = companies.find((c) => String(c.companyId) === watch('companyId'));

    // 未選択の場合は表示させる
    if (typeof selectedCompany === 'undefined') return true;

    // エージェントであれば転職先企業名を表示するため
    return selectedCompany.accountType === 2;
  };

  return (
    <main className={`${styles.main} ${styles.panelBg}`}>
      <div className={`${styles.container}`}>
        <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
          <MuiLink underline="hover" color="inherit" to="/" component={Link}>
            中国語・英語を活かしてグローバル転職 JaU
          </MuiLink>
          <Typography color="text.primary">お祝い金申請</Typography>
        </Breadcrumbs>
        <h1 className={`${styles.titleMain}`}>お祝い金申請</h1>
        <div className={styles.kv}>
          {user?.profiles?.name?.last_name && <div className={styles.name}>{user.profiles.name.last_name}様</div>}
        </div>
        <section className={`${styles.panelDefault}`}>
          <h2 className={`${styles.panelDefaultTitle}`}>お祝い金申請</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={`${styles.panelDefaultContent}`}>
            <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
              <tbody>
                <tr>
                  <th>
                    <ItemName required>内定をもらった企業</ItemName>
                  </th>
                  <td className={`${styles.formGroup}`}>
                    {!loadingForPrepare && (
                      <div className={styles.companyIdWrapper}>
                        <Select
                          className={errors?.companyId?.message ? styles.errorForm : ''}
                          control={control}
                          options={companyOptions}
                          placeholder="選択してください"
                          readonly={!!companyId}
                          useFormRegisterReturn={register('companyId', {
                            required: required('内定をもらった企業'),
                            validate: {
                              format: (value) =>
                                validate(
                                  isIn(
                                    value,
                                    companyOptions.map((c) => c.id),
                                  ),
                                  '内定をもらった企業',
                                ),
                            },
                          })}
                        />
                        <span className={`${styles.marginLeft8}`}>
                          ※ メッセージのやり取りした企業から選ぶことができます。
                        </span>
                      </div>
                    )}
                    <p className={styles.errorMessage}>{errors?.companyId?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required={!!companyId}>リクルーター名</ItemName>
                  </th>
                  <td>
                    <input
                      className={errors?.recruiter?.message ? styles.errorForm : ''}
                      type="text"
                      placeholder="入力してください"
                      {...register('recruiter', {
                        required: required('リクルーター名', !!companyId),
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.recruiter?.message}</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>入社予定日</ItemName>
                  </th>
                  <td>
                    <div className={`${styles.entry} ${styles.formGroup}`}>
                      <div className={`${styles.formGroupLeft}`}>
                        <Select
                          options={joiningYearOptions()}
                          control={control}
                          useFormRegisterReturn={register('entryYear', {
                            required: required('入社予定日'),
                          })}
                          placeholder="年"
                        />
                        <span className={`${styles.marginLeft8}`}>年</span>
                      </div>
                      <div className={`${styles.formGroupCenter}`}>
                        <Select
                          options={monthOptions}
                          control={control}
                          useFormRegisterReturn={register('entryMonth', {
                            required: required('入社予定日'),
                          })}
                          placeholder="月"
                        />
                        <span className={`${styles.marginLeft8}`}>月</span>
                      </div>
                      <div className={`${styles.formGroupRight}`}>
                        <Select
                          options={dayOptions}
                          control={control}
                          useFormRegisterReturn={register('entryDay', {
                            required: required('入社予定日'),
                          })}
                          placeholder="日"
                        />
                        <span className={`${styles.marginLeft8}`}>日</span>
                      </div>
                    </div>
                    <p className={styles.errorMessage}>
                      {errors?.entryYear?.message || errors?.entryMonth?.message || errors?.entryDay?.message}
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <ItemName required>転職先での年収</ItemName>
                  </th>
                  <td>
                    <div className={styles.salaryWrapper}>
                      <input
                        type="text"
                        placeholder="年収を入力してください"
                        {...register('salary', {
                          required: required('転職先での年収'),
                        })}
                      />
                      <span className={`${styles.marginLeft8}`}>万円</span>
                    </div>
                    <p className={styles.errorMessage}>{errors?.salary?.message}</p>
                  </td>
                </tr>
                {isShowOfferingCompanyName() && (
                  <tr>
                    <th>
                      <ItemName required>転職先企業名</ItemName>
                    </th>
                    <td>
                      <input
                        type="text"
                        placeholder="企業名を入力してください"
                        {...register('offeringCompanyName', {
                          required: required('企業名', isShowOfferingCompanyName()),
                        })}
                      />
                      <p className={styles.errorMessage}>{errors?.offeringCompanyName?.message}</p>
                    </td>
                  </tr>
                )}
                <tr>
                  <th>
                    <ItemName>
                      サービスに関しての
                      <br />
                      ご意見
                    </ItemName>
                  </th>
                  <td>
                    <div style={{ maxWidth: '750px' }}>
                      <Textarea control={control} useFormRegisterReturn={register('comment')} />
                    </div>
                    <p className={styles.errorMessage}>{errors?.comment?.message}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={`${styles.buttonGroup}`}>
              <NextButton isValid={isValid} text="報告" />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};

export default CongratulatoryGift;
