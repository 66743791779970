import { FC, useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'utils/axios';
import { JobSummary, SearchJobFormData } from 'types';
import { useForm } from 'react-hook-form';
import { minLength, pattern, required } from 'utils/validation';
import getJobs from 'requests/getJobs';
import JobList from 'components/parts/JobList';
import SearchJob from 'components/parts/SearchJob';
import iconJaU from 'images/icon_JaU.png';
import qrWebreg from 'images/qr_webreg.png';
import appStore from 'images/app_store.png';
import googlePlayBadge from 'images/google_play_badge_s.png';
import useScrollTop from 'hooks/useScrollTop';
import { getToken } from 'utils/token';
import styles from './Event.module.scss';

type EventProps = {
  eventIdentifier?: string;
};

type SignupFormData = {
  email: string;
  password: string;
  accepted: boolean;
  message: string;
};

const Event: FC<EventProps> = ({ eventIdentifier = '' }) => {
  useScrollTop();

  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  const searchJobOnSubmit = async (data: SearchJobFormData) => {
    navigate('/jobs', {
      state: data,
    });
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignupFormData>();
  const processing = useRef(false);
  const signupOnSubmit = async (data: SignupFormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const paramsCheckEmail = new URLSearchParams({
      email: data.email,
    });
    const resCheckEmail = await axios.post('/api/v10/user/check-email', paramsCheckEmail);

    if (resCheckEmail.data !== 1) {
      // メールアドレスが使用できないエラー表示
      setError('email', {
        type: 'unavailable',
        message: '登録に失敗しました。',
      });

      // 処理中フラグを折る
      processing.current = false;
      return;
    }

    const params = new URLSearchParams({
      email: data.email,
      password: data.password,
      device_type: '3',
      event_identifier: eventIdentifier,
    });
    const resPreRegister = await axios.post('/api/v10/user/pre-register', params);

    // 処理中フラグを折る
    processing.current = false;

    if (resPreRegister.status === 200) {
      // 認証メール送信完了画面に遷移.
      navigate('/verify-mail/send/done');
    }
  };

  const [pikupJobs, setPikupJobs] = useState<JobSummary[]>([]);
  const [pikupLoadingForPrepare, setPikupLoadingForPrepare] = useState(true);
  const [newJobs, setNewJobs] = useState<JobSummary[]>([]);
  const [newLoadingForPrepare, setNewLoadingForPrepare] = useState(true);

  useEffect(() => {
    setIsLogin(getToken() !== null);
  }, []);

  useEffect(() => {
    getJobs([1, 2]).then((response) => {
      setPikupJobs(response);
      setPikupLoadingForPrepare(false);
    });

    getJobs().then((response) => {
      setNewJobs(response);
      setNewLoadingForPrepare(false);
    });
  }, []);

  return (
    <>
      <main id={styles.main}>
        <div className={styles.search}>
          <SearchJob onSubmit={searchJobOnSubmit} />
        </div>
        <div className={styles.mainCont}>
          <div className={styles.bundle}>
            <div className={styles.pickup}>
              <h2>ピックアップ求人</h2>
              <JobList jobs={pikupJobs} loadingForPrepare={pikupLoadingForPrepare} />
            </div>
            <div className={styles.info}>
              {!isLogin && (
                <div className={styles.signup}>
                  <form onSubmit={handleSubmit(signupOnSubmit)} noValidate>
                    <h2>新規会員登録</h2>
                    <p>
                      <label htmlFor="signupEmail">メールアドレス</label>
                      <input
                        className={errors?.email?.message ? styles.errorForm : ''}
                        type="email"
                        id="signupEmail"
                        placeholder="入力してください"
                        {...register('email', {
                          required: required('メールアドレス'),
                          pattern: pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'メールアドレス'),
                        })}
                      />
                      <p className={styles.errorMessage}>{errors?.email?.message}</p>
                    </p>
                    <p>
                      <label htmlFor="signupPass">パスワード設定</label>
                      <input
                        className={errors?.password?.message ? styles.errorForm : ''}
                        type="password"
                        id="signupPass"
                        placeholder="入力してください"
                        {...register('password', {
                          required: required('新しいパスワード'),
                          minLength: minLength(8, '新しいパスワード', 'は半角英数字8文字以上を入力してください。'),
                          pattern: {
                            value: /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d!@#$%^&*()_+,.\\/;':-]{8,}$/,
                            message: '新しいパスワードは半角英数字8文字以上を入力してください。',
                          },
                        })}
                      />
                      <p className={styles.errorMessage}>{errors?.password?.message}</p>
                    </p>
                    <p>
                      <button type="submit" className={styles.submit}>
                        新規登録
                      </button>
                    </p>
                    <p className={styles.txtCenter}>
                      <label className={styles.accepted} htmlFor="accepted">
                        <input
                          id="accepted"
                          className={`${styles.checkboxDefault}`}
                          type="checkbox"
                          {...register('accepted', {
                            required: {
                              value: true,
                              message: '利用規約・個人情報の保護法に同意していただく必要があります',
                            },
                          })}
                        />
                        <span>
                          <a href="https://jiayou.jp/terms" target="_blank" rel="noreferrer">
                            利用規約
                          </a>
                          ・
                          <a href="https://www.clara.co.jp/privacy-policy/" target="_blank" rel="noreferrer">
                            個人情報の保護法
                          </a>
                          に同意する
                        </span>
                      </label>
                      <p className={styles.errorMessage}>{errors?.accepted?.message}</p>
                    </p>
                  </form>
                </div>
              )}
              <div className={styles.download}>
                <div className={styles.downloadQr}>
                  <img className={styles.icon} src={iconJaU} alt="JaU" />
                  <div>
                    iOSアプリ
                    <br />
                    Androidアプリ
                    <br />
                    <span className={styles.small}>今すぐダウンロード</span>
                  </div>
                  <img className={styles.qr} src={qrWebreg} alt="QRコード" />
                </div>
                <div className={styles.stores}>
                  <Link
                    to="https://jauapp.page.link/sQXj"
                    className={styles.appStoreImage}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appStore} alt="" />
                  </Link>
                  <Link
                    to="https://jauapp.page.link/V9uh"
                    className={styles.googleStoreImage}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googlePlayBadge} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.new} ${newLoadingForPrepare && styles.loading}`}>
            <h2>新着求人</h2>
            <JobList jobs={newJobs} loadingForPrepare={newLoadingForPrepare} grid />
            {newJobs.length > 0 ? (
              <a className={styles.more} href="/jobs">
                もっと見る
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </main>
      <footer className="l-footer" />
    </>
  );
};

Event.defaultProps = {
  eventIdentifier: '',
};

export default Event;
