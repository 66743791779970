import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken, isTokenExpired, removeToken } from 'utils/token';

export const useCheckToken = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) {
      removeToken();
      setTimeout(() => {
        navigate('/login');
      }, 0);
    } else {
      const token = getToken();
      if (!token) {
        navigate('/login');
      }
    }
  }, [navigate]);
};

export default useCheckToken;
