import { FC } from 'react';
import styles from './MessageRoomCard.module.scss';

type Props = {
  selected: boolean;
  companyName: string;
  body: string;
  isUnread: boolean;
  isUnreadStr?: string;
  handleClick?: () => void;
};

const MessageRoomCard: FC<Props> = ({ selected, companyName, body, isUnread, isUnreadStr, handleClick }) => {
  const isNotReplied = !isUnread && isUnreadStr === '未返信';

  return (
    <button className={`${styles.messageRoomCard} ${selected && styles.selected}`} type="button" onClick={handleClick}>
      <div className={styles.companyName}>{companyName}</div>
      <div className={styles.content}>
        <div className={styles.body}>{body || 'ファイル送信'}</div>
        <div className={styles.statusArea}>
          {isNotReplied && <span className={styles.notReplied}>未返信</span>}
          {isUnread && isUnreadStr === '未読' && <div className={styles.unread} />}
        </div>
      </div>
    </button>
  );
};

MessageRoomCard.defaultProps = {
  isUnreadStr: '',
  handleClick: undefined,
};

export default MessageRoomCard;
