import CircularProgress from '@mui/material/CircularProgress';
import Select from 'components/parts/Select';
import { FC } from 'react';
import { Control, Controller, FormState, UseFormRegister } from 'react-hook-form';
import { MypageEditFormData, Option } from 'types';
import { isIn, required, validate } from 'utils/validation';
import { workOrGraduationYearOptions, monthOptions } from 'utils/options';
import AsyncCreatableSelectInput from 'components/parts/AsyncCreatableSelectInput';
import getUniversityName from 'requests/getUniversityName';
import ItemName from 'components/parts/ItemName';
import styles from './Education.module.scss';

type Props = {
  educations: Option[];
  control: Control<MypageEditFormData>;
  educationsId: string;
  loadingInitial: boolean;
  register: UseFormRegister<MypageEditFormData>;
  errors: FormState<MypageEditFormData>['errors'];
};

const MypageEditEducation: FC<Props> = ({ educations, control, educationsId, loadingInitial, register, errors }) => {
  return (
    <section className={`${styles.panelDefault}`}>
      <h2 className={`${styles.panelDefaultTitle}`}>学歴</h2>
      <div className={`${styles.panelDefaultContent}`}>
        <table className={`${styles.tableDefault}`}>
          <tbody>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>最終学歴</ItemName>
                </div>
              </th>
              <td>
                {educations.length > 0 && !loadingInitial ? (
                  <Select
                    className={errors?.educationsId?.message ? styles.errorForm : ''}
                    placeholder="選択してください"
                    options={educations}
                    control={control}
                    useFormRegisterReturn={register('educationsId', {
                      required: required('最終学歴'),
                      validate: {
                        format: (value) =>
                          validate(
                            isIn(
                              value,
                              educations.map((e) => e.id),
                            ),
                            '最終学歴',
                          ),
                      },
                    })}
                  />
                ) : (
                  <CircularProgress />
                )}
                <p className={styles.errorMessage}>{errors?.educationsId?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName required>学校名</ItemName>
                </div>
              </th>
              <td>
                {!loadingInitial ? (
                  <div
                    className={`${styles.schoolName} ${
                      errors?.schoolName && errors.schoolName?.message ? styles.errorForm : ''
                    }`}
                  >
                    <Controller
                      control={control}
                      name="schoolName"
                      rules={{
                        required: required('学校名'),
                      }}
                      render={({ field: { onChange, value } }) => {
                        let schoolNameValue;
                        if (value) {
                          schoolNameValue = value
                            ? {
                                value,
                                label: value,
                              }
                            : [];
                        } else {
                          schoolNameValue = '';
                        }

                        return (
                          <AsyncCreatableSelectInput
                            placeholder="学校名を入力してください"
                            // 最終学歴に専門学校/短大、大学、大学院、大学院(MBA)、大学院(博士)が選択された場合のみ大学名の選択肢を表示する
                            loadOptions={['3', '4', '5', '7', '8'].includes(educationsId) && getUniversityName}
                            value={schoolNameValue}
                            onChange={(val: any) => onChange(val?.value ?? '')}
                          />
                        );
                      }}
                    />
                  </div>
                ) : (
                  <CircularProgress />
                )}
                <p className={styles.errorMessage}>{errors?.schoolName?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>学科名</ItemName>
              </th>
              <td>
                <div className={styles.department}>
                  <input type="text" placeholder="学科名を入力してください" {...register('department')} />
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName>卒業年月（予定）</ItemName>
                </div>
              </th>
              <td>
                <div className={`${styles.yearMonth} ${styles.formGroup}`}>
                  <div className={styles.formGroupLeft}>
                    <Select
                      className={errors?.graduateYear?.message ? styles.errorForm : ''}
                      options={workOrGraduationYearOptions()}
                      control={control}
                      useFormRegisterReturn={register('graduateYear', {})}
                      placeholder="年"
                    />
                    <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>年</span>
                    <Select
                      className={errors?.graduateMonth?.message ? styles.errorForm : ''}
                      options={monthOptions}
                      control={control}
                      useFormRegisterReturn={register('graduateMonth', {})}
                      placeholder="月"
                    />
                    <span className={`${styles.marginLeft8} ${styles.marginRight8}`}>月</span>
                  </div>
                </div>
                <p className={styles.errorMessage}>{errors?.graduateYear?.message || errors?.graduateMonth?.message}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default MypageEditEducation;
