import { FC } from 'react';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import styles from './NextButton.module.scss';

type Props = {
  isValid: boolean;
  text?: string;
};

const NextButton: FC<Props> = ({ isValid, text }) => {
  return (
    <button
      disabled={!isValid}
      type="submit"
      className={`${styles.buttonRound} ${isValid ? styles.buttonPrimary : styles.buttonPrimaryDisabled} ${
        styles.buttonMd
      } `}
    >
      {text}
      <span className={`${styles.materialIcons}`}>
        <ArrowForwardIosRoundedIcon />
      </span>
    </button>
  );
};

NextButton.defaultProps = {
  text: '次へ',
};

export default NextButton;
