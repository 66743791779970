import { FC, useRef } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useForm } from 'react-hook-form';
import axios from 'utils/axios';
import { pattern, required } from 'utils/validation';
import useCheckNotLogin from 'hooks/useCheckNotLogin';
import useScrollTop from 'hooks/useScrollTop';
import styles from './ForgetPassword.module.scss';

type FormData = {
  email: string;
};

const ForgetPassword: FC = () => {
  // ログイン中であればマイページ登録情報に飛ばす
  useCheckNotLogin();
  useScrollTop();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const processing = useRef(false);
  const onSubmit = async (data: FormData) => {
    // 多重クリック禁止
    if (processing.current) return;
    // 処理中フラグを立てる
    processing.current = true;

    const { location } = window;
    const url = `${location.protocol}//${location.host}/reset-password`;
    const params = new URLSearchParams({
      email: data.email,
      url,
      device_type: '3',
    });

    await axios.post('/api/v8/user/reset/password/email/web', params);

    // 処理中フラグを折る
    processing.current = false;
    navigate('/reset-mail');
  };

  return (
    <>
      <main className={`${styles.main} ${styles.panelBg}`}>
        <div className={`${styles.container}`}>
          <Breadcrumbs aria-label="breadcrumb" separator=">" className={`${styles.breadcrumb}`}>
            <MuiLink underline="hover" color="inherit" to="/" component={Link}>
              中国語・英語を活かしてグローバル転職 JaU
            </MuiLink>
            <MuiLink underline="hover" color="inherit" to="/login" component={Link}>
              ログイン
            </MuiLink>
            <Typography color="text.primary">パスワード再設定</Typography>
          </Breadcrumbs>
          <h1 className={`${styles.titleMain}`}>パスワード再設定</h1>
          <section className={`${styles.panelDefault}`}>
            <h2 className={`${styles.panelDefaultTitle}`}>パスワードを忘れた方</h2>
            <form className={`${styles.panelDefaultContent}`} onSubmit={handleSubmit(onSubmit)} noValidate>
              <p className={`${styles.marginBottomMd}`}>
                ご登録のメールアドレスを入力してください。
                <br />
                ご入力いただいたメールアドレスにパスワード再設定のご案内のメールをお送りいたします。
                <br />
                <br />
                メールに記載されているパスワード再設定用URLにアクセスして、新しいパスワードを設定してください。
              </p>
              <table className={`${styles.tableDefault} ${styles.marginBottomMd}`}>
                <tr>
                  <th>メールアドレス</th>
                  <td>
                    <input
                      className={errors?.email?.message ? styles.errorForm : ''}
                      type="email"
                      placeholder="メールアドレスを入力してください"
                      {...register('email', {
                        required: required('メールアドレス'),
                        pattern: pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'メールアドレス'),
                      })}
                    />
                    <p className={styles.errorMessage}>{errors?.email?.message}</p>
                  </td>
                </tr>
              </table>
              <button type="submit" className={`${styles.buttonRound} ${styles.buttonPrimary} ${styles.buttonMd}`}>
                再設定
                <span className={`${styles.materialIcons}`}>
                  <ArrowForwardIosRoundedIcon />
                </span>
              </button>
            </form>
          </section>
        </div>
      </main>
    </>
  );
};

export default ForgetPassword;
