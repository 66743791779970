import { FC } from 'react';
import MessageRoomCard from 'components/parts/MessageRoomCard';
import { MessageRoom } from 'types';
import { CircularProgress } from '@mui/material';
import styles from './MessageRoomList.module.scss';

type Props = {
  messageRooms: MessageRoom[];
  selectedId: number | null;
  loadingForPrepare: boolean;
  changeSelectedId: (id: number | null) => void;
};

const MessageList: FC<Props> = ({ messageRooms, selectedId, loadingForPrepare, changeSelectedId }) => {
  return (
    <>
      <div className={styles.header}>メッセージ一覧</div>
      <div className={`${styles.messageListArea} ${loadingForPrepare && styles.loading}`}>
        {!loadingForPrepare ? (
          messageRooms.map((mr) => (
            <MessageRoomCard
              key={mr.id}
              companyName={mr.companyName}
              body={mr.body}
              isUnread={mr.isUnread}
              isUnreadStr={mr.isUnreadStr}
              selected={selectedId === mr.companyId}
              handleClick={() => changeSelectedId(mr.companyId)}
            />
          ))
        ) : (
          <CircularProgress size={60} />
        )}
      </div>
    </>
  );
};

export default MessageList;
