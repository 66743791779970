import { FC } from 'react';
import { Link } from 'react-router-dom';
import logoMono from 'images/logo-mono.png';
import qrWebreg from 'images/qr_webreg.png';
import appStore from 'images/app_store.png';
import googlePlayBadge from 'images/google_play_badge.png';
import styles from './Footer.module.scss';

type Props = {
  isMessage?: boolean;
  isSimple?: boolean;
};

const Footer: FC<Props> = ({ isMessage, isSimple }) => {
  return (
    <>
      {!isMessage && (
        <footer className={`${styles.footerSp}`}>
          <p className={`${styles.footerLogo}`}>
            <img src={logoMono} alt="ジャーヨウ" />
            ジャーヨウ
          </p>
          <div className={`${styles.link}`}>
            <ul>
              <li>
                <Link to="https://www.clara.co.jp/company/profile/" target="_blank" rel="noreferrer">
                  会社概要
                </Link>
              </li>
              <li>
                <Link to="https://jiayou.jp/contact" target="_blank" rel="noreferrer">
                  運営へお問い合わせ
                </Link>
              </li>
              <li>
                <Link to="https://www.clara.co.jp/privacy-policy/" target="_blank" rel="noreferrer">
                  プライバシーポリシー
                </Link>
              </li>
              <li>
                <Link to="https://jiayou.jp/terms" target="_blank" rel="noreferrer">
                  利用規約
                </Link>
              </li>
            </ul>
            <div className={styles.divider} />
            <ul>
              <li>
                <Link to="https://jiayou.jp/company-lp" target="_blank" rel="noreferrer">
                  企業の採用担当者様 お問い合わせ
                </Link>
              </li>
              <li>
                <Link to="https://www.consulting.clara.jp/jau/lp01/contact/" target="_blank" rel="noreferrer">
                  ヘッドハンター様 お問い合わせ
                </Link>
              </li>
            </ul>
          </div>
          <p className={`${styles.footerBottom}`}>
            <small>© 2022 CLARA, Inc.</small>
          </p>
        </footer>
      )}
      {!isSimple && (
        <footer className={`${styles.footerPc}`}>
          <div className={`${styles.footerContainer}`}>
            <div className={`${styles.footerLogo}`}>
              <img src={logoMono} alt="ジャーヨウ" />
              <p className={styles.logoText}>ジャーヨウ</p>
            </div>
            <div className={`${styles.footerContent}`}>
              <div className={`${styles.text}`}>
                JaU（ジャーヨウ）は日本企業の中国ビジネス、日本進出している中国企業の事業責任者・プロジェクトマネージャーなど中国ビジネスに特化したハイクラス転職サービスです。
              </div>
              <div className={`${styles.link}`}>
                <ul>
                  <li>
                    <a href="https://www.clara.co.jp/company/profile/" target="_blank" rel="noreferrer">
                      会社概要
                    </a>
                  </li>
                  <li>
                    <a href="https://jiayou.jp/contact" target="_blank" rel="noreferrer">
                      運営へお問い合わせ
                    </a>
                  </li>
                  <li>
                    <a href="https://www.clara.co.jp/privacy-policy/" target="_blank" rel="noreferrer">
                      プライバシーポリシー
                    </a>
                  </li>
                  <li>
                    <a href="https://jiayou.jp/terms" target="_blank" rel="noreferrer">
                      利用規約
                    </a>
                  </li>
                </ul>
              </div>
              <div className={`${styles.link}`}>
                <ul>
                  <li>
                    <a href="https://jiayou.jp/company-lp" target="_blank" rel="noreferrer">
                      企業の採用担当者様 お問い合わせ
                    </a>
                  </li>
                  <li>
                    <a href="https://www.consulting.clara.jp/jau/lp01/contact/" target="_blank" rel="noreferrer">
                      ヘッドハンター様 お問い合わせ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`${styles.footerStoreImages}`}>
              <div>
                <span>アプリのダウンロードはこちら</span>
              </div>
              <div>
                <img className={`${styles.qr}`} src={qrWebreg} alt="" />
                <Link to="https://jauapp.page.link/sQXj" target="_blank" rel="noreferrer">
                  <img src={appStore} alt="" />
                </Link>
                <Link
                  to="https://jauapp.page.link/V9uh"
                  className={styles.googleStoreImage}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlePlayBadge} alt="" />
                </Link>
              </div>
            </div>
            <div className={`${styles.footerBottom}`}>
              <small>© 2022 CLARA, Inc.</small>
            </div>
          </div>
        </footer>
      )}
      {isSimple && (
        <footer className={`${styles.footerPc} ${styles.simple}`}>
          <div className={`${styles.footerContainer}`}>
            <div className={`${styles.footerLogo}`}>
              <img src={logoMono} alt="ジャーヨウ" />
            </div>
            <div className={`${styles.footerContent}`}>
              <div className={`${styles.link}`}>
                <ul>
                  <li>
                    <a href="https://www.clara.co.jp/company/profile/" target="_blank" rel="noreferrer">
                      会社概要
                    </a>
                  </li>
                  <li>
                    <a href="https://jiayou.jp/contact" target="_blank" rel="noreferrer">
                      運営へお問い合わせ
                    </a>
                  </li>
                  <li>
                    <a href="https://www.clara.co.jp/privacy-policy/" target="_blank" rel="noreferrer">
                      プライバシーポリシー
                    </a>
                  </li>
                  <li>
                    <a href="https://jiayou.jp/terms" target="_blank" rel="noreferrer">
                      利用規約
                    </a>
                  </li>
                  <li>
                    <a href="https://jiayou.jp/company-lp" target="_blank" rel="noreferrer">
                      企業の採用担当者様 お問い合わせ
                    </a>
                  </li>
                  <li>
                    <a href="https://www.consulting.clara.jp/jau/lp01/contact/" target="_blank" rel="noreferrer">
                      ヘッドハンター様 お問い合わせ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`${styles.footerBottom}`}>
              <small>© 2022 CLARA, Inc.</small>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

Footer.defaultProps = {
  isMessage: false,
  isSimple: false,
};

export default Footer;
