import { useEffect, useState } from 'react';
import { Region } from 'types';
import axios from 'utils/axios';

const useGetRegionMaster = (): {
  regions: Region[];
} => {
  const [regions, setRegions] = useState<Region[]>([]);

  // 居住国マスター取得
  useEffect(() => {
    const countriesReq = async () => {
      const res = await axios.get('/api/company/master/countries', {});
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const regionsData = res.data
          .map((country) =>
            country.detail.map((d: any) => ({
              id: String(d.id),
              name: d.name,
              countryId: String(country.id),
            })),
          )
          .flat();

        setRegions(regionsData);
      }
    };

    if (regions.length === 0) countriesReq();
  });

  return {
    regions,
  };
};

export default useGetRegionMaster;
