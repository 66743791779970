import { FC, useEffect, useState } from 'react';
import { Control, useController, UseFormRegisterReturn } from 'react-hook-form';
import { Optgroup, Option } from 'types';
import styles from './Select.module.scss';

type Props = {
  className?: string;
  options?: Option[];
  optgroups?: Optgroup[];
  placeholder?: string;
  useFormRegisterReturn?: UseFormRegisterReturn;
  control?: Control<any>;
  readonly?: boolean;
};

const Select: FC<Props> = ({
  className,
  options,
  optgroups,
  useFormRegisterReturn,
  placeholder,
  control,
  readonly,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name: useFormRegisterReturn?.name as string,
    control,
  });

  const [isDefault, setIsDefault] = useState<boolean>(typeof value === 'undefined' || value === '');
  useEffect(() => {
    setIsDefault(typeof value === 'undefined' || value === '');
  }, [value]);

  let labelValue = placeholder;
  if (options && options.length > 0) {
    labelValue = options.find((o) => o.id === value)?.name || placeholder;
  } else if (optgroups && optgroups.length > 0) {
    labelValue =
      optgroups
        .map((og) => og.options)
        .flat()
        .find((o) => o.id === value)?.name || placeholder;
  }

  return (
    <div className={styles.selectWrapper}>
      <p
        className={`${className} ${styles.selectLabel} ${isDefault ? styles.gray : styles.black} ${
          readonly && styles.readonly
        }`}
      >
        {labelValue}
      </p>
      <select {...useFormRegisterReturn} value={value} onChange={onChange}>
        {placeholder && (
          <option value="" disabled={readonly}>
            {placeholder}
          </option>
        )}
        {options &&
          options.length > 0 &&
          options?.map((o) => (
            <option key={o.id} value={o.id} disabled={readonly && o.id !== value}>
              {o.name}
            </option>
          ))}
        {optgroups &&
          optgroups.length > 0 &&
          optgroups?.map((og) => (
            <optgroup key={og.label} label={og.label}>
              {og.options.map((o) => (
                <option key={o.id} value={o.id} disabled={readonly && o.id !== value}>
                  {o.name}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    </div>
  );
};

Select.defaultProps = {
  className: undefined,
  placeholder: undefined,
  options: [],
  optgroups: [],
  useFormRegisterReturn: undefined,
  control: undefined,
  readonly: false,
};

export default Select;
