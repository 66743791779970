import { FC } from 'react';
import ReactSelect, { components, createFilter } from 'react-select';
import { Optgroup, Option } from 'types';
import styles from './SelectInput.module.scss';

type Props = {
  className?: string;
  value?: any;
  options?: Option[];
  optgroups?: Optgroup[];
  placeholder?: string;
  isMulti?: boolean;
  onChange?: any;
};

const DropdownIndicator = (props: any) => {
  return (
    <div>
      <components.DropdownIndicator {...props}>
        <div className={styles.dropdownIndicator} />
      </components.DropdownIndicator>
    </div>
  );
};

const SelectInput: FC<Props> = ({ className, value, options, optgroups, placeholder, isMulti, onChange }) => {
  const selectOptions =
    options && options.length > 0
      ? options?.map((o) => ({
          value: o.id,
          label: o.name,
        }))
      : optgroups?.map((og) => ({
          label: og.label,
          options: og.options.map((o) => ({
            value: o.id,
            label: o.name,
          })),
        }));

  return (
    <div>
      <ReactSelect
        className={className}
        placeholder={placeholder}
        isMulti={isMulti}
        options={selectOptions}
        components={{ DropdownIndicator }}
        onChange={onChange}
        styles={{
          placeholder: (defaultStyles) => ({
            ...defaultStyles,
          }),
          dropdownIndicator: (defaultStyles) => ({
            ...defaultStyles,
            display: 'none',
          }),
          indicatorSeparator: (defaultStyles) => ({
            ...defaultStyles,
            display: 'none',
          }),
        }}
        value={value}
        filterOption={createFilter({
          ignoreAccents: false,
        })}
      />
    </div>
  );
};

SelectInput.defaultProps = {
  className: undefined,
  value: undefined,
  placeholder: undefined,
  options: [],
  optgroups: [],
  isMulti: false,
  onChange: undefined,
};

export default SelectInput;
