import { CircularProgress } from '@mui/material';
import Select from 'components/parts/Select';
import SelectInput from 'components/parts/SelectInput';
import { FC } from 'react';
import { Control, Controller, FormState, UseFormRegister } from 'react-hook-form';
import { MypageEditFormData, Optgroup, Option } from 'types';
import { isIn, required, validate } from 'utils/validation';
import ItemName from 'components/parts/ItemName';
import Textarea from 'components/parts/Textarea';
import styles from './Request.module.scss';

type Props = {
  salaries: Option[];
  industries: Optgroup[];
  occupations: Optgroup[];
  locations: Option[];
  control: Control<MypageEditFormData>;
  loadingInitial: boolean;
  register: UseFormRegister<MypageEditFormData>;
  errors: FormState<MypageEditFormData>['errors'];
};

const MypageEditRequest: FC<Props> = ({
  salaries,
  industries,
  occupations,
  locations,
  control,
  loadingInitial,
  register,
  errors,
}) => {
  return (
    <section className={`${styles.panelDefault}`}>
      <h2 className={`${styles.panelDefaultTitle}`}>希望情報</h2>
      <div className={`${styles.panelDefaultContent}`}>
        <table className={`${styles.tableDefault}`}>
          <tbody>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName>希望年収</ItemName>
                </div>
              </th>
              <td>
                <Select
                  className={errors?.salaryId?.message ? styles.errorForm : ''}
                  placeholder="選択してください"
                  options={salaries}
                  control={control}
                  useFormRegisterReturn={register('salaryId', {
                    validate: {
                      format: (value) =>
                        validate(
                          value === '' ||
                            isIn(
                              value,
                              salaries.map((e) => e.id),
                            ),
                          '希望年収',
                        ),
                    },
                  })}
                />
                <p className={styles.errorMessage}>{errors?.salaryId?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName>希望業界</ItemName>
                </div>
              </th>
              <td>
                {industries.length > 0 && !loadingInitial ? (
                  <div className={styles.acquireLanguage}>
                    <Controller
                      control={control}
                      name="industryIds"
                      rules={{
                        validate: {
                          format: (values) =>
                            validate(
                              !Array.isArray(values) ||
                                values.every((v) => isIn(v, industries.map((i) => i.options.map((o) => o.id)).flat())),
                              '希望業界',
                            ),
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <SelectInput
                            placeholder="選択してください（複数選択可）"
                            isMulti
                            optgroups={industries}
                            value={
                              value
                                ? industries
                                    .map((i) => i.options)
                                    .flat()
                                    .filter((i) => value.includes(i.id))
                                    .map((i) => ({
                                      value: i.id,
                                      label: i.name,
                                    }))
                                : []
                            }
                            onChange={(val: any[]) => onChange(val.map((v) => v.value))}
                          />
                        );
                      }}
                    />
                  </div>
                ) : (
                  <CircularProgress />
                )}
                <p className={styles.errorMessage}>{(errors?.industryIds as any)?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <div className={styles.tableHeader}>
                  <ItemName>希望職種</ItemName>
                </div>
              </th>
              <td>
                {occupations.length > 0 && !loadingInitial ? (
                  <div className={styles.acquireLanguage}>
                    <Controller
                      control={control}
                      name="occupationIds"
                      rules={{
                        validate: {
                          format: (values) =>
                            validate(
                              !Array.isArray(values) ||
                                values.every((v) => isIn(v, occupations.map((i) => i.options.map((o) => o.id)).flat())),
                              '希望職種',
                            ),
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <SelectInput
                            placeholder="選択してください（複数選択可）"
                            isMulti
                            optgroups={occupations}
                            value={
                              value
                                ? occupations
                                    .map((i) => i.options)
                                    .flat()
                                    .filter((i) => value.includes(i.id))
                                    .map((i) => ({
                                      value: i.id,
                                      label: i.name,
                                    }))
                                : []
                            }
                            onChange={(val: any[]) => onChange(val.map((v) => v.value))}
                          />
                        );
                      }}
                    />
                  </div>
                ) : (
                  <CircularProgress />
                )}
                <p className={styles.errorMessage}>{(errors?.occupationIds as any)?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName required>希望勤務地</ItemName>
              </th>
              <td>
                {locations.length > 0 && !loadingInitial ? (
                  <div className={styles.acquireLanguage}>
                    <Controller
                      control={control}
                      name="locationIds"
                      rules={{
                        required: required('希望勤務地'),
                        validate: {
                          format: (values) =>
                            validate(
                              !Array.isArray(values) ||
                                values.every((v) =>
                                  isIn(
                                    v,
                                    locations.map((s) => s.id),
                                  ),
                                ),
                              '希望勤務地',
                            ),
                        },
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <SelectInput
                            placeholder="選択してください（複数選択可）"
                            isMulti
                            options={locations}
                            value={
                              value
                                ? locations
                                    .filter((s) => value.includes(s.id))
                                    .map((s) => ({
                                      value: s.id,
                                      label: s.name,
                                    }))
                                : []
                            }
                            onChange={(val: any[]) => onChange(val.map((v) => v.value))}
                          />
                        );
                      }}
                    />
                  </div>
                ) : (
                  <CircularProgress />
                )}
                <p className={styles.errorMessage}>{(errors?.locationIds as any)?.message}</p>
              </td>
            </tr>
            <tr>
              <th>
                <ItemName>その他</ItemName>
              </th>
              <td>
                {/* eslint-disable-next-line */}
                <Textarea
                  control={control}
                  useFormRegisterReturn={register('other')}
                  placeholder={`例）
会社の特色
自社サービスに携わりたい。
働き方
ワーク・ライフ・バランスを大事にしたい。
仕事内容
新規事業を立ち上げたい。`}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default MypageEditRequest;
