/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export function useHashFragment(offset = 0, trigger = true) {
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace('#', ''));

      if (!elementToScroll) return;

      window.scrollTo({
        top: elementToScroll.offsetTop - offset,
        behavior: 'smooth',
      });
    };

    if (!trigger) return;

    scrollToHashElement();
    window.addEventListener('hashchange', scrollToHashElement);
    // eslint-disable-next-line consistent-return
    return window.removeEventListener('hashchange', scrollToHashElement);
  }, [trigger]);
}

export default useHashFragment;
