import axios from 'utils/axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from 'utils/token';

const useCheckNotLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = async () => {
      const token = getToken();
      if (!token) {
        return;
      }
      const res = await axios
        .get('/api/v10/user/refresh', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          // noop
        });
      if (res && res.status === 200) {
        navigate('/mypage');
      }
    };

    auth();
  }, [navigate]);
};

export default useCheckNotLogin;
