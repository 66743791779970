import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Option } from 'types';
import axios from 'utils/axios';
import { getToken } from 'utils/token';

const useGetWorkStatusMaster = (
  navigate: NavigateFunction,
): {
  workStatuses: Option[];
} => {
  const [workStatuses, setWorkStatuses] = useState<Option[]>([]);
  useEffect(() => {
    const req = async () => {
      const token = getToken();
      if (!token) {
        navigate('/login');
        return;
      }
      const res = await axios
        .get('/api/v14/master/work-statuses', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate('/login');
        });
      if (res && res.status === 200 && Array.isArray(res.data)) {
        const data = res.data.map((workStatus) => {
          return {
            id: String(workStatus.id),
            name: workStatus.name,
          };
        });
        setWorkStatuses(data);
      }
    };

    req();
  }, [navigate]);

  return {
    workStatuses,
  };
};

export default useGetWorkStatusMaster;
