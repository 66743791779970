const KEY = 'accessToken';
const EXPIRATION_KEY = 'tokenExpiration';

export const getToken = (): string | null => {
  return localStorage.getItem(KEY);
};

export const setToken = (token: string, expiration?: string): void => {
  localStorage.setItem(KEY, token);
  if (expiration) {
    localStorage.setItem(EXPIRATION_KEY, expiration);
  }
};

export const removeToken = (): void => {
  localStorage.removeItem(KEY);
  localStorage.removeItem(EXPIRATION_KEY);
};

export const isTokenExpired = (): boolean => {
  const expiration = localStorage.getItem(EXPIRATION_KEY);
  if (!expiration) {
    return true;
  }
  const currentTimestamp = new Date().toISOString();
  return currentTimestamp > expiration;
};
