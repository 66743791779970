import { useForm } from 'react-hook-form';
import { MypageEditFormData } from 'types';

const useFormMypageEdit = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<MypageEditFormData>({
    mode: 'onChange',
    defaultValues: {
      birthyear: '1990',
      birthmonth: '4',
      birthday: '1',
      graduateYear: '2010',
      graduateMonth: '3',
    },
  });

  return {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    errors,
    isValid,
  };
};

export default useFormMypageEdit;
